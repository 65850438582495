<script setup>
import { ref, onMounted, computed } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);
const cities = ref(null);
const selectedDistrict = ref([]);
const searchTerm = ref("");
const filteredItems = ref(null);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  localStorage.setItem("selectedDistrict", null);
  getCities();
});

async function getCities() {
  await axios
    .get(`${PROD_URL}/api/regions/cities-has-catering`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      cities.value = res.data.data.cities;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function onInputChange(event) {
  filteredItems.value = cities.value.filter((item) =>
    item.name.toLowerCase().includes(event.target.value.toLowerCase())
  );
}

function selectDistrict(id) {
  const tempSelectedDistrict = selectedDistrict.value;
  if (tempSelectedDistrict.includes(id)) {
    tempSelectedDistrict.filter((value) => value !== id);
  } else {
    tempSelectedDistrict.push(id);
  }
  selectedDistrict.value = tempSelectedDistrict;
}

function handleSearch() {
  localStorage.setItem(
    "selectedDistrict",
    JSON.stringify(selectedDistrict.value)
  );
  router.push("/home");
}
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-px-4 sm:tw-px-4 md:tw-px-52 lg:tw-px-52 tw-mt-4"
  >
    <div class="tw-flex tw-flex-row tw-items-center">
      <font-awesome-icon
        class="tw-text-black tw-pr-5"
        size="2xl"
        :icon="['fas', 'chevron-left']"
      />
      <div class="tw-basis-11/12 tw-mr-5">
        <input
          class="tw-w-full tw-rounded-lg tw-border-2 tw-border-gray-400 tw-p-3 tw-text-sm"
          placeholder="Cari kota catering..."
          type="text"
          id="search"
          v-model="searchTerm"
          @input="onInputChange"
        />
      </div>
      <div class="tw-basis-1/12">
        <button
          @click="handleSearch"
          class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-indigo-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-indigo-300 focus:tw-ring-opacity-80"
        >
          <font-awesome-icon
            class="tw-text-white tw-pr-1"
            size="lg"
            :icon="['fas', 'magnifying-glass']"
          />
          <span class="tw-mx-1">Cari</span>
        </button>
      </div>
    </div>

    <p class="tw-mt-5 tw-text-gray-500 tw-text-base">
      Area Mitra Catering Kami
    </p>
    <div class="tw-grid tw-divide-y tw-divide-neutral-200 tw-w-full">
      <div
        class="tw-py-5"
        v-for="(city, cityIndex) in filteredItems ?? cities"
        :key="cityIndex"
      >
        <details class="tw-group">
          <summary
            class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none"
          >
            <span class="tw-text-black tw-text-lg">{{ city.name }}</span>
            <font-awesome-icon
              icon="fa-solid fa-angle-down"
              class="tw-text-black icon-lg"
            />
          </summary>
          <fieldset class="tw-mt-4">
            <legend class="tw-sr-only">Checkboxes</legend>
            <div class="tw-space-y-2">
              <label
                v-for="(district, districtIndex) in city.districts"
                :key="districtIndex"
                :for="district.id"
                class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50"
              >
                <div class="tw-flex tw-items-center">
                  &#8203;
                  <input
                    @click="selectDistrict(district.id)"
                    type="checkbox"
                    class="tw-size-4 tw-rounded tw-border-gray-300"
                    :id="district.id"
                  />
                </div>
                <div>
                  <strong class="tw-font-medium tw-text-gray-900">{{
                    district.name
                  }}</strong>
                </div>
              </label>
            </div>
          </fieldset>
        </details>
      </div>
    </div>
  </div>
</template>
