// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAS4UG0fnxT-Tb5XhgLZJRw_yN34RcCbpA",
  authDomain: "caricatering.firebaseapp.com",
  projectId: "caricatering",
  storageBucket: "caricatering.appspot.com",
  messagingSenderId: "615907241673",
  appId: "1:615907241673:web:1c871b6346e2e03090e718"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
