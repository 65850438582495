<script setup>
import { ref, onMounted } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const swiperModule = [Pagination, Navigation];
const router = useRouter();
const token = ref(null);
const user = ref(null);
const outletId = ref(null);
const caterings = ref([]);
const packages = ref([]);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  getDatas();
});

async function getDatas() {
  await axios
    .get(`${PROD_URL}/api/owner/caterings`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      caterings.value = res.data.data.caterings;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function gotoCateringForm(id = null) {
  if (id) {
    router.push("/catering-form?" + "id=" + id);
  } else {
    router.push("/catering-form");
  }
}

function gotoPackageForm(id = null) {
  if (id) {
    router.push("/package-form?" + "id=" + id);
  } else {
    router.push("/package-form");
  }
}

function gotoOwnerHome() {
  router.push("/owner-home");
}

function gotoHome() {
  router.push("/home");
}

function gotoPromotionCatering() {
  router.push("/promotion-catering");
}

function gotoPremiumPage() {
  router.push("/premium-page");
}

async function handlePackage(id) {
  outletId.value = id;
  localStorage.setItem("selectedOutletId", id);

  await axios
    .get(`${PROD_URL}/api/owner/packages?catering_id=` + id, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      packages.value = res.data.data.packages;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function handlePackageBack() {
  outletId.value = null;
  packages.value = [];
}

function gotoChat(id) {
  router.push("/chat/owner?catering_id=" + id);
}
</script>

<template>
  <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
    <aside class="tw-w-64 tw-bg-white tw-text-black">
      <nav>
        <div
          class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white"
        >
          <div class="tw-px-4 tw-py-6">
            <div
              class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100"
            >
              <a
                href="#"
                class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50"
              >
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  class="tw-size-10 tw-rounded-full tw-object-cover"
                />

                <div>
                  <p class="tw-text-xs">
                    <strong class="tw-block tw-text-black tw-font-medium"
                      >Hallo,</strong
                    >

                    <span class="tw-text-black">{{ user?.user?.name }}</span>
                  </p>
                </div>
              </a>
            </div>
            <ul class="tw-mt-6 tw-space-y-1">
              <li>
                <button
                  @click="gotoOwnerHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  @click="handlePackageBack()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-catering"
                >
                  Catering Saya
                </button>
              </li>
              <li>
                <details
                  class="tw-group [&_summary::-webkit-details-marker]:tw-hidden"
                >
                  <summary
                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                  >
                    <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                    <span
                      class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-size-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>
                  <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                    <li>
                      <button
                        @click="gotoPromotionCatering()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Highlight Catering
                      </button>
                    </li>
                    <li>
                      <button
                        @click="gotoPremiumPage()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Halaman Premium Catering
                      </button>
                    </li>
                    <li>
                      <button
                        href="#"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Foto dan Desain Catering
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <button
                  @click="gotoHome"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-red-100 hover:tw-text-red-600"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>
    <section class="tw-w-full">
      <div
        class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-px-8 tw-py-6"
      >
        <div
          v-if="outletId"
          @click="handlePackageBack"
          class="tw-flex tw-items-center"
        >
          <font-awesome-icon
            class="tw-text-black tw-pr-2"
            size="lg"
            :icon="['fas', 'chevron-left']"
          />
          <div
            class="tw-text-xl tw-text-lg tw-font-semibold tw-text-gray-800 tw-capitalize lg:tw-text-2xl tw-text-black"
          >
            Kembali ke Catering
          </div>
        </div>
        <div v-else class="tw-flex">
          <img
            src="@/assets/caricatering.png"
            class="tw-mr-2 tw-h-8 sm:tw-h-8 md:tw-h-10 lg:tw-h-10"
          />
          <h1
            class="tw-text-2xl tw-font-semibold tw-text-gray-800 tw-capitalize lg:tw-text-3xl tw-text-black"
          >
            Hallo, {{ user?.user?.name }}
          </h1>
        </div>
        <button
          v-if="outletId"
          @click="gotoPackageForm()"
          class="tw-flex tw-items-center tw-justify-center tw-w-1/2 tw-mr-1 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-green-500 tw-rounded-lg hover:tw-bg-green-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-green-300 focus:tw-ring-opacity-80 tw-w-max"
        >
          <font-awesome-icon
            class="tw-text-white tw-pr-2"
            size="lg"
            :icon="['fas', 'plus']"
          />
          <span class="tw-mx-1">Buat Paket</span>
        </button>
        <button
          v-else
          @click="gotoCateringForm()"
          class="tw-flex tw-items-center tw-justify-center tw-w-1/2 tw-mr-1 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-green-500 tw-rounded-lg hover:tw-bg-green-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-green-300 focus:tw-ring-opacity-80 tw-w-max"
        >
          <font-awesome-icon
            class="tw-text-white tw-pr-2"
            size="lg"
            :icon="['fas', 'plus']"
          />
          <span class="tw-mx-1">Buat Catering</span>
        </button>
      </div>
      <div
        v-if="outletId"
        class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4 tw-px-8 tw-pb-12"
      >
        <div
          class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-4"
          v-for="(pack, packageIndex) in packages"
          :key="packageIndex"
        >
          <div class="tw-h-48 tw-w-full">
            <swiper
              class="swiper-backface-hidden"
              id="product_photos_swiper"
              :pagination="{
                dynamicBullets: true,
              }"
              :modules="swiperModule"
            >
              <swiper-slide
                v-for="(image, imageIndex) in pack.images"
                :key="imageIndex"
              >
                <img
                  :src="image.url_path"
                  alt=""
                  class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg"
                />
              </swiper-slide>
            </swiper>
          </div>
          <div class="tw-text-sm tw-mt-4">{{ pack.category.id_name }}</div>
          <div class="tw-text-lg tw-text-black">{{ pack.name }}</div>
          <div class="tw-flex tw-flex-wrap tw-mt-1">
            <div
              v-for="(menu, menuIndex) in pack.menus"
              :key="menuIndex"
              class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600"
            >
              {{ menu.name }}
            </div>
          </div>
          <p class="tw-text-gray-400 tw-mt-1">
            *pilihan {{ pack.min_menu_selection }} -
            {{ pack.max_menu_selection }} menu / paket
          </p>
          <p class="tw-text-catering tw-font-semibold tw-mt-1">
            Rp{{ $numFormat(pack.price) }} / paket
          </p>
          <div class="tw-mt-1 tw-flex tw-flex-row tw-justify-between tw-w-full">
            <button
              @click="gotoPackageForm(pack.id)"
              class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-blue-600 tw-rounded-lg hover:tw-bg-blue-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 focus:tw-ring-opacity-80"
            >
              <font-awesome-icon
                class="tw-text-white tw-pr-1"
                size="lg"
                :icon="['fas', 'pencil']"
              />
              <span class="tw-mx-1">Edit</span>
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4 tw-px-8 tw-pb-12"
      >
        <div
          class="tw-h-fit tw-min-h-fit"
          v-for="(catering, cateringIndex) in caterings"
          :key="cateringIndex"
        >
          <div
            class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative"
          >
            <div
              class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg"
            >
              <img
                class="tw-object-cover tw-object-center tw-w-full tw-h-40"
                :src="catering.image.url_path"
                alt="avatar"
              />
              <div class="tw-px-3 tw-py-2">
                <p class="tw-text-gray-400 text-ellipsis">
                  {{
                    catering.categories.map((item) => item.id_name).join(", ")
                  }}
                </p>
                <div
                  class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis"
                >
                  {{ catering.name }}
                </div>
                <div
                    class="tw-text-xs"
                    :class="
                      catering.verification_status == 'unverified'
                        ? 'tw-text-red-600'
                        : 'tw-text-green-600'
                    "
                  >
                  {{ catering.verification_status }}
                </div>
              </div>
              <div
                class="tw-px-2 tw-mb-1 tw-flex tw-flex-row tw-justify-between tw-w-full"
              >
                <button
                  @click="gotoCateringForm(catering.id)"
                  class="tw-flex tw-items-center tw-justify-center tw-w-1/2 tw-mr-1 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-blue-600 tw-rounded-lg hover:tw-bg-blue-500 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 focus:tw-ring-opacity-80"
                >
                  <font-awesome-icon
                    class="tw-text-white tw-pr-1"
                    size="lg"
                    :icon="['fas', 'pencil']"
                  />
                  <span class="tw-mx-1">Edit</span>
                </button>
                <button
                  @click="handlePackage(catering.id)"
                  class="tw-flex tw-items-center tw-justify-center tw-w-1/2 tw-ml-1 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-yellow-400 tw-rounded-lg hover:tw-bg-yellow-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-yellow-200 focus:tw-ring-opacity-80"
                >
                  <font-awesome-icon
                    class="tw-text-white tw-pr-1"
                    size="lg"
                    :icon="['fas', 'bag-shopping']"
                  />
                  <span class="tw-mx-1">Paket</span>
                </button>
              </div>
              <div class="tw-p-2">
                <button
                  @click="gotoChat(catering.id)"
                  class="tw-flex tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-catering focus:tw-outline-none focus:tw-ring focus:tw-ring-catering focus:tw-ring-opacity-80"
                >
                  <font-awesome-icon
                    class="tw-text-white tw-pr-1"
                    size="lg"
                    :icon="['fas', 'comments']"
                  />
                  <span class="tw-mx-1">Transaksi</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
