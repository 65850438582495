<script setup>
import { ref, onMounted } from "vue"
// import { PROD_URL } from "@/utils/url"
import axios from "axios"
import { useRouter } from "vue-router"
import { createToaster } from "@meforma/vue-toaster"
import { PROD_URL } from "@/utils/url"

const route = useRouter()
const token = ref(null)
const toaster = createToaster({ position: "bottom" })
const isLoading = ref(true)
const isLogin = ref(true)
const name = ref(null)
const email = ref(null)
const password = ref(null)
const confirmPassword = ref(null)
const notelp = ref(null)

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null
})

function handleSubmit() {
    if(isLogin.value) {
        const formData = {
            email: email.value,
            password: password.value
        }
        axios
        .post(PROD_URL + "/api/auth/login", formData)
        .then((res) => {
            console.log(res.data.status)
            if (res.data.status == 200) {
                localStorage.setItem("token", res.data.data.access_token)
                localStorage.setItem("user", JSON.stringify(res.data.data))

                route.push("/home")
                toaster.show('Berhasil masuk')
            } else {
                toaster.show(res.data.message)
            }
        })
        .catch((err) => {
            toaster.show(err.response.data.message)
            isLoading.value = false
        })
    } else {
        if (password.value != confirmPassword.value) {
            toaster.show("Password tidak sama")
            isLoading.value = false
        } else {
            const formData = {
            name: name.value,
            email: email.value,
            password: password.value,
            password_confirmation: confirmPassword.value,
            phone_number: notelp.value,
            }
            axios
            .post(PROD_URL + "/api/auth/register", formData)
            .then((res) => {
                console.log(res.data.status)
                if (res.data.status == 201) {
                localStorage.setItem("user", JSON.stringify(res.data.data))

                toaster.show("Pendaftaran berhasil, silahkan Login")
                isLogin.value = true
                } else {
                    toaster.show(res.data.message)
                }
                
            })
            .catch((err) => {
                toaster.show(err.response.data.message)
                isLoading.value = false
            })
        }
    }
}
</script>

<template>
  <section class="tw-bg-white">
    <div class="lg:tw-grid lg:tw-min-h-screen lg:tw-grid-cols-12">
      <section
        class="tw-relative tw-flex tw-h-32 tw-items-end tw-bg-gray-900 lg:tw-col-span-5 lg:tw-h-full tw-xl:col-span-6"
      >
        <img
          alt=""
          src="@/assets/home1.jpg"
          class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-opacity-80"
        />

        <div class="tw-hidden lg:tw-relative lg:tw-block lg:tw-p-12">
          <a class="tw-block tw-text-white" href="#">
            <span class="tw-sr-only">Home</span>
            <img
              src="@/assets/caricatering.png"
              alt="Logo"
              class="tw-h-16 sm:tw-h-16 md:tw-h-20 lg:tw-h-20"
            />
          </a>

          <h2
            class="tw-mt-6 tw-text-2xl tw-font-bold tw-text-white sm:tw-text-3xl md:tw-text-4xl"
          >
            Selamat Datang
          </h2>

          <p class="tw-mt-4 tw-leading-relaxed tw-text-white/90">
            Dapatkan "info catering murah dan lengkap" hanya di Cari Catering.
          </p>
        </div>
      </section>

      <main
        class="tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-8 sm:tw-px-12 lg:tw-col-span-7 lg:tw-px-20 lg:tw-py-12 tw-xl:col-span-6"
      >
        <div class="tw-w-full">
          <div class="tw-relative tw--mt-16 tw-block lg:tw-hidden">
            <a
              class="tw-inline-flex tw-size-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-blue-600 sm:tw-size-20"
              href="#"
            >
              <span class="tw-sr-only">Home</span>
              <img
                src="@/assets/caricatering.png"
                alt="Logo"
                class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12"
              />
            </a>
            <h2
              class="tw-mt-6 tw-text-2xl tw-font-bold tw-text-gray-900 sm:tw-text-3xl"
            >
              Selamat Datang
            </h2>
            <p class="tw-mt-4 tw-leading-relaxed tw-text-gray-500">
              Dapatkan "info catering murah dan lengkap" hanya di Cari Catering.
            </p>
          </div>

          <div class="tw-mt-8 tw-grid tw-grid-cols-6 tw-gap-6">
            <div v-if="!isLogin" class="tw-col-span-6">
              <label
                for="Name"
                class="tw-block tw-text-sm tw-font-medium tw-text-catering"
              >
                Nama
              </label>
              <input
                v-model="name"
                type="text"
                id="Name"
                name="name"
                class="tw-p-2 tw-w-full tw-rounded-md tw-border-gray-800 tw-bg-white tw-text-sm tw-text-catering tw-shadow-md"
              />
            </div>
            <div class="tw-col-span-6">
              <label
                for="Email"
                class="tw-block tw-text-sm tw-font-medium tw-text-catering"
              >
                Email
              </label>
              <input
                v-model="email"
                type="email"
                id="Email"
                name="email"
                class="tw-p-2 tw-w-full tw-rounded-md tw-border-gray-800 tw-bg-white tw-text-sm tw-text-catering tw-shadow-md"
              />
            </div>
            <div class="tw-col-span-6">
              <label
                for="Password"
                class="tw-block tw-text-sm tw-font-medium tw-text-catering"
              >
                Password
              </label>
              <input
                v-model="password"
                type="password"
                id="Password"
                name="password"
                class="tw-p-2 tw-w-full tw-rounded-md tw-border-gray-800 tw-bg-white tw-text-sm tw-text-catering tw-shadow-md"
              />
            </div>
            <div v-if="!isLogin" class="tw-col-span-6">
              <label
                for="ConfirmPassword"
                class="tw-block tw-text-sm tw-font-medium tw-text-catering"
              >
                Konfirmasi Password
              </label>
              <input
                v-model="confirmPassword"
                type="password"
                id="ConfirmPassword"
                name="confirmPassword"
                class="tw-p-2 tw-w-full tw-rounded-md tw-border-gray-800 tw-bg-white tw-text-sm tw-text-catering tw-shadow-md"
              />
            </div>
            <div v-if="!isLogin" class="tw-col-span-6">
              <label
                for="Notelp"
                class="tw-block tw-text-sm tw-font-medium tw-text-catering"
              >
                No. Telepon
              </label>
              <input
                v-model="notelp"
                type="text"
                id="Notelp"
                name="name"
                class="tw-p-2 tw-w-full tw-rounded-md tw-border-gray-800 tw-bg-white tw-text-sm tw-text-catering tw-shadow-md"
              />
            </div>
            <!-- <div class="tw-col-span-6">
                            <label for="MarketingAccept" class="tw-flex tw-gap-4">
                                <input type="checkbox" id="MarketingAccept" name="marketing_accept"
                                    class="tw-h-5 tw-w-5 tw-rounded-md tw-border-gray-200 tw-bg-white tw-shadow-sm" />
                                <span class="tw-text-sm tw-text-catering">
                                    I want to receive emails about events, product updates, and company announcements.
                                </span>
                            </label>
                        </div> -->
            <div v-if="!isLogin" class="tw-col-span-6">
              <p class="tw-text-sm tw-text-gray-500">
                By creating an account, you agree to our
                <a href="#" class="tw-text-catering tw-underline"
                  >terms and conditions</a
                >
                and
                <a href="#" class="tw-text-catering tw-underline"
                  >privacy policy</a
                >.
              </p>
            </div>
            <div
              class="tw-col-span-6 sm:tw-flex sm:tw-items-center sm:tw-gap-4"
            >
              <button
                @click="handleSubmit"
                class="tw-inline-block tw-shrink-0 tw-rounded-md tw-border tw-border-catering tw-bg-catering tw-px-12 tw-py-3 tw-text-sm tw-font-medium tw-text-white tw-transition hover:tw-bg-transparent hover:tw-text-catering focus:tw-outline-none focus:tw-ring active:tw-text-gray-500"
              >
                {{isLogin ? 'Masuk' :'Buat Akun'}}
              </button>
              <p class="tw-mt-4 tw-text-sm tw-text-gray-500 sm:tw-mt-0">
                {{isLogin ? 'Belum' :'Sudah'}} punya Akun?
                <button @click="isLogin = !isLogin" class="tw-text-catering tw-underline">{{isLogin ? 'Daftar Sekarang' :'Masuk Sekarang'}}</button>.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </section>
</template>
