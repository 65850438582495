<script setup>
import { db } from "@/plugins/firebase";
import {
  collection,
  onSnapshot,
  doc,
  addDoc,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
// import { collection, doc, setDoc, onSnapshot } from "firebase/firestore"
// import { getStorage, uploadBytes, ref, getDownloadURL } from "firebase/storage"
import { ref, onMounted } from "vue";
import { PROD_URL } from "@/utils/url"
import axios from "axios";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const token = ref(null);
const items = ref(null);
const user = ref(null);
const message = ref("");
const from = ref("");
const chats = ref([]);
const selectedChat = ref(null);
const chatListData = ref([])
const isDropdownProfile = ref(false);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  from.value = router.currentRoute.value.params.page;
  if(route.query.chat_id){
    selectedChat.value = route.query.chat_id
  }
  fetchData();
  fetchDataList();
});

async function fetchDataList() {
  const query = await getDocs(collection(db, "users"));
  const temp = [];
  const tempList = [];
  query.forEach((doc) => {
    if (
      from.value == "customer" &&
      doc.id.split(",")[1] == user.value.user.id
    ) {
      tempList.push(doc.id.split(",")[0])
      if(selectedChat.value && doc.id == selectedChat.value){
        temp.push({ id: doc.id, isSelected: true });
      } else {
        temp.push({ id: doc.id, isSelected: false });
      }
    } else if (
      from.value == "owner" &&
      doc.id.split(",")[0] == route.query.catering_id
    ) {
      tempList.push(doc.id.split(",")[1])
      temp.push({ id: doc.id, isSelected: false });
    }
  });
  chats.value = temp;
  console.log(tempList)

  const _url = from.value == 'customer' ? `${PROD_URL}/api/caterings/by-ids` : `${PROD_URL}/api/users/by-ids`

  await axios
    .get(_url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
      params: {
        ids: tempList
      }
    })
    .then((res) => {
      const tempDatas = []
      const datas = res.data.data.caterings
      console.log(datas)
      chats.value.forEach((v) => {
        datas.forEach((x) => {
          if((from.value == 'customer' ? v.id.split(',')[0] : v.id.split(',')[1]) == x.id.toString()){
            tempDatas.push(x)
          }
        })
      })
      chatListData.value = tempDatas
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function fetchData() {
  if (selectedChat.value) {
    let docRef = doc(db, "users", selectedChat.value);
    const subcollectionRef = query(
      collection(docRef, "chats"),
      orderBy("createdAt")
    );
    onSnapshot(subcollectionRef, (subData) => {
      let itemsTemp = [];
      subData.forEach((v) => {
        itemsTemp.push(v.data());
      });
      items.value = itemsTemp;
    });
  }
}

function handleEnterKey(event) {
  if (event.keyCode === 13) {
    sendMessage();
  }
}

async function sendMessage() {
  const subcollectionRef = collection(
    doc(db, "users", selectedChat.value),
    "chats"
  );
  addDoc(subcollectionRef, {
    from: from.value,
    text: message.value,
    createdAt: new Date(),
  });
  message.value = "";
}

async function selectChat(v) {
  selectedChat.value = v;
  chats.value.forEach((chat) => {
    if (chat.id == v) {
      chat.isSelected = true;
    } else {
      chat.isSelected = false;
    }
  });
  let docRef = doc(db, "users", v);
  const subcollectionRef = query(
    collection(docRef, "chats"),
    orderBy("createdAt")
  );
  onSnapshot(subcollectionRef, (subData) => {
    let itemsTemp = [];
    subData.forEach((value) => {
      itemsTemp.push(value.data());
    });
    items.value = itemsTemp;
  });
}

function doSignOut() {
  axios
    .post(PROD_URL + "/api/auth/logout", "", {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.clear();

      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function profileDropdown() {
  isDropdownProfile.value = !isDropdownProfile.value;
}

function gotoAuth() {
  router.push("/auth");
}

function gotoHome() {
  router.push("/home");
}

function gotoIndex() {
  router.push("/");
}

function gotoOwner() {
  router.push("/owner-home");
}

function goBack() {
  router.back();
}
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <nav
      class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-white tw-shadow"
    >
      <button
        @click="gotoIndex"
        class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4"
      >
        <img
          src="@/assets/caricatering.png"
          alt="Logo"
          class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12"
        />
        <a
          href="#"
          class="tw-text-catering tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg"
          >CariCatering.id</a
        >
      </button>
      <div class="tw-flex tw-items-center tw-space-x-4">
        <div v-if="token" class="tw-relative">
          <button
            @click="profileDropdown"
            class="tw-flex tw-items-center tw-text-black"
          >
            Hallo, {{ user?.user?.name }}
            <span
              class="tw-transition"
              :class="isDropdownProfile ? 'tw-rotate-180' : ''"
            >
              <svg
                class="tw-ml-1 tw-w-4 tw-h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button>
          <div
            class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
            :class="isDropdownProfile ? '' : 'tw-hidden'"
            style="z-index: 1"
          >
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-3"
                :icon="['fas', 'user']"
              />
              <a @click="gotoOwner">Owner</a>
            </div>
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-3"
                :icon="['fas', 'sign-out']"
              />
              <a @click="doSignOut">Log Out</a>
            </div>
          </div>
        </div>
        <button
          v-if="!token"
          @click="gotoAuth"
          class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded"
        >
          Masuk
        </button>
      </div>
    </nav>

    <div class="tw-mt-2 tw-shadow-lg tw-rounded-lg tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32">
      <div class="tw-flex tw-flex-row tw-justify-between tw-bg-white tw-h-90vh">
        <div
          class="tw-flex tw-flex-col tw-w-2/5 tw-border-r-2 tw-overflow-y-auto"
        >
          <div class="tw-border-b-2 tw-py-4 tw-px-2">
            <div @click="goBack" class="tw-flex">
              <font-awesome-icon
                class="tw-text-black tw-pr-2 tw-pt-1.5"
                size="lg"
                :icon="['fas', 'chevron-left']"
              />
              <h2 class="tw-text-lg tw-text-black tw-mb-1">
                Kembali
              </h2>
            </div>
          </div>
          <div
            @click="selectChat(chat.id)"
            v-for="(chat, chatIndex) in chats"
            :key="chatIndex"
            :class="chat.isSelected ? 'tw-border-l-4 tw-border-catering' : ''"
            class="tw-flex tw-flex-row tw-py-4 tw-px-2 tw-items-center tw-border-b-2"
          >
            <div class="tw-w-full">
              <div
                class="tw-text-lg tw-font-semibold tw-text-black tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-w-full"
              >
                {{ chatListData[chatIndex]?.name }}
              </div>
              <div v-if="from == 'customer'"
                class="tw-text-gray-500 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-w-full"
              >
              {{ chatListData[chatIndex]?.district.name.toLowerCase() }}, {{ chatListData[chatIndex]?.city.name.toLowerCase() }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-w-full tw-px-5 tw-flex tw-flex-col tw-justify-between tw-overflow-y-auto"
        >
          <div class="tw-flex tw-flex-col tw-mt-5">
            <div
              :class="
                v.from == from
                  ? 'tw-flex tw-justify-end tw-mb-4'
                  : 'tw-flex tw-justify-start tw-mb-4'
              "
              v-for="(v, i) in items"
              :key="i"
            >
              <div
                :class="
                  v.from == from
                    ? 'tw-mr-2 tw-py-3 tw-px-4 tw-bg-blue-400 tw-rounded-bl-3xl tw-rounded-tl-3xl tw-rounded-tr-xl tw-text-white'
                    : 'tw-ml-2 tw-py-3 tw-px-4 tw-bg-gray-400 tw-rounded-br-3xl tw-rounded-tr-3xl tw-rounded-tl-xl tw-text-white'
                "
              >
                {{ v.text }}
              </div>
            </div>
          </div>
          <div v-if="selectedChat" class="tw-flex tw-flex-row tw-mb-4">
            <input
              @keypress="handleEnterKey"
              type="text"
              name="message"
              id="message"
              class="tw-mr-3 tw-inline-flex tw-w-full tw-relative tw-rounded-lg tw-border-0 tw-py-2 tw-pr-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder-text-gray-400 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-catering sm:tw-text-sm sm:tw-leading-6 tw-pl-3"
              placeholder="Pesan..."
              v-model="message"
            />
            <button
              @click="sendMessage"
              class="tw-px-3 tw-py-2 tw-border-y tw-border tw-rounded-lg tw-border-gray-300"
            >
              <font-awesome-icon :icon="['fas', 'paper-plane']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
