<script setup>
import { ref, onMounted, watch } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);
const selectedImage = ref(null);
const imageUrl = ref(null);
const isLoading = ref(false);
const user = ref(null);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
});

async function handleSubmit() {
  if (!isLoading.value) {
    isLoading.value = true;
    const formDataSubmit = {
      catering_id: router.currentRoute.value.params.id,
    };
    if (selectedImage.value) {
      formDataSubmit.payment = selectedImage.value;
    }
    console.log(formDataSubmit);

    await axios
      .post(`${PROD_URL}/api/owner/highlights`, formDataSubmit, {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        isLoading.value = false;
        gotoPromotion();
      })
      .catch((err) => {
        isLoading.value = false;
        if (err.response.status == 401) {
          router.push("/auth");
        }
      });
  }
}

function onImageChange(event) {
  selectedImage.value = event.target.files[0];
  if (selectedImage.value) {
    imageUrl.value = URL.createObjectURL(selectedImage.value);
  }
}

function gotoPromotion() {
  router.push("/promotion-catering");
}

function gotoCateringHome() {
  router.push("/owner-catering");
}

function gotoOwnerHome() {
  router.push("/owner-home");
}

function gotoHome() {
  router.push("/home");
}

function gotoPromotionCatering() {
  router.push("/promotion-catering");
}

function gotoPremiumPage() {
  router.push("/premium-page");
}
</script>

<template>
  <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
    <aside class="tw-w-64 tw-bg-white tw-text-black">
      <nav>
        <div
          class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white"
        >
          <div class="tw-px-4 tw-py-6">
            <div
              class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100"
            >
              <a
                href="#"
                class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50"
              >
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  class="tw-size-10 tw-rounded-full tw-object-cover"
                />

                <div>
                  <p class="tw-text-xs">
                    <strong class="tw-block tw-text-black tw-font-medium"
                      >Hallo,</strong
                    >

                    <span class="tw-text-black">{{ user?.user?.name }}</span>
                  </p>
                </div>
              </a>
            </div>
            <ul class="tw-mt-6 tw-space-y-1">
              <li>
                <button
                  @click="gotoOwnerHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  @click="gotoCateringHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Catering Saya
                </button>
              </li>
              <li>
                <details
                  class="tw-group [&_summary::-webkit-details-marker]:tw-hidden"
                >
                  <summary
                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                  >
                    <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                    <span
                      class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-size-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>
                  <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                    <li>
                      <button
                        @click="gotoPromotionCatering()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Highlight Catering
                      </button>
                    </li>
                    <li>
                      <button
                        @click="gotoPremiumPage()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Halaman Premium Catering
                      </button>
                    </li>
                    <li>
                      <button
                        href="#"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Foto dan Desain Catering
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <button
                  @click="gotoHome"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-red-100 hover:tw-text-red-600"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>

    <div
      class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12"
    >
      <div
        class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <h2
          class="tw-sm:text-3xl tw-text-2xl tw-leading-6 tw-font-bold tw-text-center tw-text-black tw-mb-4"
        >
          Pembayaran Promosi
        </h2>
        <label class="tw-block tw-mt-8 tw-text-sm tw-font-medium tw-leading-6">
          Nama Catering
        </label>
        <p class="tw-sm:text-lg tw-text-base tw-text-left tw-text-black">
          Catering
        </p>
        <label class="tw-block tw-mt-4 tw-text-sm tw-font-medium tw-leading-6">
          Bukti Pembayaran Promosi Highlight
        </label>
        <div class="tw-flex tw-flex-col tw-mb-2">
          <div class="w-text-base tw-text-left tw-text-black">
            Kirim bukti pembayaran di:
          </div>
          <div class="w-text-base tw-text-left tw-text-black">
            BCA 1300337381 Deni Dwi Candra
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
          <label
            for="dropzone-file"
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-40 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 tw-hover:bg-gray-100"
          >
            <img
              class="tw-w-36 tw-h-36"
              :src="imageUrl"
              v-if="imageUrl"
              alt="Selected image"
            />
            <div
              v-else
              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6"
            >
              <svg
                class="tw-w-8 tw-h-8 tw-mb-4 tw-text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h3"
                />
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9 11 2 2 4-4M12 15v-7"
                />
              </svg>
              <p class="tw-mb-2 tw-text-sm tw-text-gray-500">
                <span class="tw-font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p class="tw-text-xs tw-text-gray-500">PNG dan JPG(MAX. 2mb)</p>
            </div>
            <input
              @change="onImageChange"
              id="dropzone-file"
              type="file"
              class="tw-hidden"
              accept="image/*"
            />
          </label>
        </div>
        <div
          class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8"
        >
          <button
            @click="handleSubmit()"
            type="submit"
            class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500"
          >
            Proses
          </button>
          <button
            @click="gotoPromotion()"
            type="button"
            class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500 tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >
            Kembali
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
