<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

import home1 from "@/assets/home1.jpg";
import home2 from "@/assets/home2.jpg";
import home3 from "@/assets/home3.jpg";

const router = useRouter();
const token = ref(null);
const user = ref(null);
const isDropdownCategory = ref(false);
const isDropdownProfile = ref(false);
const caterings = ref([]);
const carouselData = ref([
  {
    value: home1,
  },
  {
    value: home2,
  },
  {
    value: home3,
  },
]);
const carouselIndex = ref(0);
const images = ref([home1, home2, home3]);

const currentIndex = ref(0);
const currentImage = ref(images.value[currentIndex.value]);

const changeImage = () => {
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
  currentImage.value = images.value[currentIndex.value];
};

let imageInterval;

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  imageInterval = setInterval(changeImage, 5000);
  getDatas();
});

onBeforeUnmount(() => {
  clearInterval(imageInterval);
});

async function getDatas() {
  await axios
    .get(`${PROD_URL}/api/home`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      caterings.value = res.data.data.caterings;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function next() {
  if (carouselIndex.value < carouselData.value.length - 1) {
    carouselIndex.value++;
  } else {
    carouselIndex.value = 0;
  }
}

function prev() {
  if (carouselIndex.value > 0) {
    carouselIndex.value--;
  } else {
    carouselIndex.value = carouselData.value.length - 1;
  }
}

function doSignOut() {
  axios
    .post(PROD_URL + "/api/auth/logout", "", {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.clear();

      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function filteredCaterings(v) {
  if (v == "surabaya") {
    localStorage.setItem(
      "selectedDistrict",
      "[3906,3907,3908,3909,3910,3911,3912,3913,3914,3915,3916,3917,3918,3919,3920,3921,3922,3923,3924,3925,3926,3927,3928,3929,3930,3931,3932,3933,3934,3935,3936]"
    );
  } else if (v == "sidoarjo") {
    localStorage.setItem(
      "selectedDistrict",
      "[3586,3587,3588,3589,3590,3591,3592,3593,3594,3595,3596,3597,3598,3599,3600,3601,3602,3603]"
    );
  } else if (v == "batu") {
    localStorage.setItem("selectedDistrict", "[3937,3938,3939]");
  } else if (v == "malang") {
    localStorage.setItem(
      "selectedDistrict",
      "[3937,3938,3939,3388,3389,3390,3391,3392,3393,3394,3395,3396,3397,3398,3399,3400,3401,3402,3403,3404,3405,3406,3407,3408,3409,3410,3411,3412,3413,3414,3415,3416,3417,3418,3419,3420]"
    );
  }
  router.push("/home");
}

function openDropdown() {
  isDropdownCategory.value = !isDropdownCategory.value;
}

function profileDropdown() {
  isDropdownProfile.value = !isDropdownProfile.value;
}

function gotoSearch() {
  router.push("/search");
}

function gotoDetail(id) {
  router.push("/outlet-detail/" + id);
}

function gotoAuth() {
  router.push("/auth");
}

function gotoHome() {
  router.push("/");
}

function gotoOwner() {
  router.push("/owner-home");
}

function gotoChat() {
  router.push("/chat/customer");
}
</script>

<style scoped>
.tw-carousel-item {
  transition: tw-opacity 0.5s ease-in-out;
}
</style>

<template>
  <div class="tw-flex tw-flex-col tw-mb-12">
    <section
      :style="{
        backgroundImage: `url(${currentImage})`,
      }"
      class="tw-relative tw-bg-cover tw-bg-center tw-bg-no-repeat"
    >
      <div class="tw-absolute tw-inset-0 tw-bg-gray-900/75"></div>
      <nav
        class="tw-absolute tw-w-full tw-flex tw-items-center tw-justify-between tw-py-8 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-transparent"
      >
        <button
          @click="gotoHome"
          class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4"
        >
          <img
            src="@/assets/caricatering.png"
            alt="Logo"
            class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12"
          />
          <div
            class="tw-text-white tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg"
          >
            CariCatering.id
          </div>
        </button>
        <div class="tw-flex tw-items-center tw-space-x-4">
          <div
            class="tw-relative tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >
            <!-- <button
              @click="openDropdown"
              class="tw-flex tw-items-center tw-text-white"
            >
              Cari Apa?
              <span
                class="tw-transition"
                :class="isDropdownCategory ? 'tw-rotate-180' : ''"
              >
                <svg
                  class="tw-ml-1 tw-w-4 tw-h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </button> -->
            <div
              class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
              :class="isDropdownCategory ? '' : 'tw-hidden'"
              style="z-index: 1"
            >
              <a
                href="#"
                class="tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
                >Option 1</a
              >
              <a
                href="#"
                class="tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
                >Option 2</a
              >
              <a
                href="#"
                class="tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
                >Option 3</a
              >
            </div>
          </div>
          <!-- <a
            href="#"
            class="tw-text-white tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
            >Pusat Bantuan</a
          >
          <a
            href="#"
            class="tw-text-white tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
            >Syarat dan Ketentuan</a
          > -->
          <div v-if="token" class="tw-relative">
            <button
              @click="profileDropdown"
              class="tw-flex tw-items-center tw-text-white"
            >
              Hallo, {{ user?.user?.name }}
              <span
                class="tw-transition"
                :class="isDropdownProfile ? 'tw-rotate-180' : ''"
              >
                <svg
                  class="tw-ml-1 tw-w-4 tw-h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </button>
            <div
              class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
              :class="isDropdownProfile ? '' : 'tw-hidden'"
              style="z-index: 1"
            >
              <div
                class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
              >
                <font-awesome-icon
                  class="tw-pt-1 tw-pr-2"
                  :icon="['fas', 'comments']"
                />
                <a @click="gotoChat">Chat</a>
              </div>
              <div
                class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
              >
                <font-awesome-icon
                  class="tw-pt-1 tw-pr-3"
                  :icon="['fas', 'user']"
                />
                <a @click="gotoOwner">Owner</a>
              </div>
              <div
                class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
              >
                <font-awesome-icon
                  class="tw-pt-1 tw-pr-3"
                  :icon="['fas', 'sign-out']"
                />
                <a @click="doSignOut">Log Out</a>
              </div>
            </div>
          </div>
          <button
            v-if="!token"
            @click="gotoAuth"
            class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded"
          >
            Masuk
          </button>
        </div>
      </nav>
      <div
        class="tw-relative tw-max-w-screen-xl tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-py-0 tw-flex tw-h-96 sm:tw-h-96 md:tw-h-screen lg:tw-h-screen tw-mt-28 sm:tw-mt-28 md:tw-mt-72 lg:tw-mt-72"
      >
        <div class="tw-max-w-3xl ltr:sm:tw-text-left rtl:sm:tw-text-right">
          <div class="tw-pt-10">
            <div class="">
              <h1
                class="tw-text-2xl sm:tw-text-2xl md:tw-text-5xl lg:tw-text-5xl tw-font-bold tw-text-white"
              >
                Mau cari catering?
              </h1>
              <p
                class="tw-mt-2 tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-base lg:tw-text-base"
              >
                Dapatkan infonya dan langsung pesan di Cari Catering.
              </p>
            </div>
            <div class="tw-mt-8 tw-flex">
              <button
                @click="gotoSearch"
                class="tw-relative tw-w-full tw-max-w-md"
              >
                <input
                  type="text"
                  placeholder="Masukan lokasi/area/alamat"
                  class="tw-w-full tw-py-3 tw-px-4 tw-rounded-full tw-border tw-border-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-600"
                />
                <button
                  @click="gotoSearch"
                  class="tw-absolute tw-right-2 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-bg-catering tw-text-white tw-px-4 tw-py-2 tw-rounded-full"
                >
                  Cari
                </button>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section
      class="tw-w-full tw-pt-8 sm:tw-pt-8 md:tw-pt-16 lg:tw-pt-16 tw-pb-6 sm:tw-pb-6 md:tw-pb-12 lg:tw-pb-12 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32"
    >
      <div
        class="tw-relative tw-overflow-hidden tw-h-48 sm:tw-h-48 md:tw-h-72 lg:tw-h-72"
      >
        <div
          class="tw-carousel-item tw-absolute tw-opacity-100 tw-w-full tw-h-72"
        >
          <img
            :src="carouselData[carouselIndex].value"
            class="tw-w-full tw-h-72 tw-object-cover"
          />
        </div>
        <button
          @click="prev"
          class="tw-absolute tw-left-0 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-bg-white tw-bg-opacity-50 tw-rounded-full tw-p-2 tw-mx-4 hover:tw-bg-opacity-100"
        >
          &#10094
        </button>
        <button
          @click="next"
          class="tw-absolute tw-right-0 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-bg-white tw-bg-opacity-50 tw-rounded-full tw-p-2 tw-mx-4 hover:tw-bg-opacity-100"
        >
          &#10095
        </button>
        <div
          class="tw-absolute tw-bottom-4 tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-flex"
        >
          <span
            class="tw-mx-1 tw-w-3 tw-h-3 tw-bg-white tw-rounded-full tw-cursor-pointer"
            v-for="(data, index) in carouselData"
            :key="index"
            :class="
              index == carouselIndex ? 'tw-bg-opacity-100' : 'tw-bg-opacity-50'
            "
          ></span>
        </div>
      </div>
    </section> -->

    <div
      class="tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-pb-6 sm:tw-pb-6 md:tw-pb-8 lg:tw-pb-8 tw-pt-8 sm:tw-pt-8 md:tw-pt-16 lg:tw-pt-16"
    >
      <section class="tw-bg-white tw-shadow-md tw-rounded-lg">
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex-1 tw-p-6">
            <h2
              class="tw-text-xl sm:tw-text-xl md:tw-text-2xl lg:tw-text-2xl tw-font-semibold tw-text-black"
            >
              Daftarkan Catering Anda di Cari Catering
            </h2>
            <p
              class="tw-text-gray-600 tw-mt-2 tw-text-xs sm:tw-text-xs md:tw-text-base lg:tw-text-base"
            >
              Nikmati berbagai fitur dan layanan eksklusif untuk mempromosikan
              dan mengelola bisnis catering Anda dengan lebih efektif. Dapatkan
              visibilitas yang lebih tinggi dan jangkau pelanggan baru di
              wilayah Anda. Tingkatkan pertumbuhan bisnis catering Anda
              sekarang!
            </p>
            <button
              @click="gotoOwner"
              class="tw-inline-block tw-mt-4 tw-px-6 tw-py-2 tw-text-catering tw-border tw-border-catering tw-rounded-lg hover:tw-bg-catering hover:tw-text-white transition"
            >
              Ayo Darftarkan!
            </button>
          </div>
          <img
            src="@/assets/promotion_create_catering_image.jpg"
            class="tw-h-32 tw-w-72 tw-rounded-lg tw-hidden sm:tw-hidden md:tw-block lg:tw-block tw-h-fit"
          />
        </div>
      </section>
      <!-- <section class="tw-bg-white tw-shadow-md tw-rounded-lg tw-p-6">
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex-1">
            <h2 class="tw-text-xl sm:tw-text-xl md:tw-text-2xl lg:tw-text-2xl tw-font-semibold tw-text-black">Coba cara
              baru bayar catering!</h2>
            <p class="tw-text-gray-600 tw-mt-2 tw-text-xs sm:tw-text-xs md:tw-text-base lg:tw-text-base">Biar bayar
              catering lebih gampang dan aman, coba sistem pembayaran
              khusus
              buat bisnis catering.</p>
            <button href="#" class="tw-inline-block tw-mt-4 tw-text-catering hover:tw-underline">Mau coba
              dong</button>
          </div>
        </div>
      </section> -->
    </div>

    <p
      class="tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-mb-4 tw-text-black tw-text-3xl"
    >
      Rekomendasi
    </p>
    <div class="tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-pb-8">
      <div class="tw-flex tw-flex-row tw-overflow-x-auto no-scrollbar">
        <div
          @click="gotoDetail(catering.id)"
          v-for="(catering, cateringIndex) in caterings"
          :key="cateringIndex"
        >
          <div
            class="tw-flex-shrink-0 tw-w-56 sm:tw-w-56 md:tw-w-72 lg:tw-w-72 tw-h-1/2 tw-mr-4 tw-mb-4 tw-rounded-lg tw-shadow-md tw-relative"
          >
            <div
              class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg"
            >
              <img
                class="tw-object-cover tw-object-center tw-w-full tw-h-36 sm:tw-h-36 md:tw-h-40 lg:tw-h-40"
                :src="catering.image.url_path"
                alt="avatar"
              />
              <div
                class="tw-px-3 sm:tw-px-3 md:tw-px-6 lg:tw-px-6 tw-py-2 sm:tw-py-2 md:tw-py-4 lg:tw-py-4"
              >
                <p class="tw-text-gray-400 text-ellipsis">
                  {{
                    catering.categories.map((item) => item.id_name).join(", ")
                  }}
                </p>
                <div
                  class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis"
                >
                  {{ catering.name }}
                </div>
                <div
                  class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering"
                >
                  Rp. {{ $numFormat(catering.min_price) }} - Rp.
                  {{ $numFormat(catering.max_price) }}
                </div>
                <div class="tw-flex tw-mt-2 tw-text-gray-400">
                  <h1 class="tw-text-sm">
                    {{ catering.district.name }}, {{ catering.city.name }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p
      class="tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-mb-4 tw-text-black tw-text-3xl"
    >
      Area Mitra Catering Kami
    </p>
    <div
      class="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32"
    >
      <button
        @click="filteredCaterings('surabaya')"
        class="tw-relative tw-w-full tw-h-56 sm:tw-h-56 md:tw-h-64 lg:tw-h-64 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <img
          src="@/assets/surabaya.jpg"
          alt="Catering Image"
          class="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
        />
        <div
          class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black/50 tw-rounded-lg"
        >
          <h3 class="tw-text-xl tw-font-bold tw-text-white tw-text-center">
            Catering Surabaya
          </h3>
        </div>
      </button>
      <button
        @click="filteredCaterings('sidoarjo')"
        class="tw-relative tw-w-full tw-h-56 sm:tw-h-56 md:tw-h-64 lg:tw-h-64 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <img
          src="@/assets/sidoarjo.jpg"
          alt="Catering Image"
          class="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
        />
        <div
          class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black/50 tw-rounded-lg"
        >
          <h3 class="tw-text-xl tw-font-bold tw-text-white tw-text-center">
            Catering Sidoarjo
          </h3>
        </div>
      </button>
      <button
        @click="filteredCaterings('malang')"
        class="tw-relative tw-w-full tw-h-56 sm:tw-h-56 md:tw-h-64 lg:tw-h-64 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <img
          src="@/assets/malang.jpg"
          alt="Catering Image"
          class="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
        />
        <div
          class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black/50 tw-rounded-lg"
        >
          <h3 class="tw-text-xl tw-font-bold tw-text-white tw-text-center">
            Catering Malang
          </h3>
        </div>
      </button>
      <button
        @click="filteredCaterings('batu')"
        class="tw-relative tw-w-full tw-h-56 sm:tw-h-56 md:tw-h-64 lg:tw-h-64 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <img
          src="@/assets/batu.jpg"
          alt="Catering Image"
          class="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
        />
        <div
          class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black/50 tw-rounded-lg"
        >
          <h3 class="tw-text-xl tw-font-bold tw-text-white tw-text-center">
            Catering Batu
          </h3>
        </div>
      </button>
    </div>
  </div>

  <footer>
    <div
      class="tw-bg-white tw-py-6 tw-border-t tw-border-gray-200 tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
    >
      <div
        class="tw-flex tw-flex-col tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-space-y-6"
      >
        <div class="tw-flex-1 tw-flex tw-justify-between">
          <div>
            <div class="tw-flex tw-items-center">
              <img
                src="@/assets/caricatering.png"
                alt="Cari Catering Logo"
                class="tw-w-20"
              />
              <p class="tw-ml-4 tw-text-catering tw-text-3xl">Cari Catering</p>
            </div>
            <p class="tw-mt-2">
              Dapatkan "info catering murah dan lengkap" hanya di Cari Catering.
            </p>
          </div>
          <div>
            <h3 class="tw-text-gray-700 tw-uppercase">Cari Catering</h3>
            <ul class="tw-mt-2 tw-space-y-2">
              <li>
                <a href="#" class="tw-text-gray-600 hover:tw-underline"
                  >Tentang Kami</a
                >
              </li>
              <li>
                <a href="#" class="tw-text-gray-600 hover:tw-underline"
                  >Job Cari Catering</a
                >
              </li>
              <li>
                <a href="#" class="tw-text-gray-600 hover:tw-underline"
                  >Promosikan Catering Anda</a
                >
              </li>
              <li>
                <a href="#" class="tw-text-gray-600 hover:tw-underline"
                  >Pusat Bantuan</a
                >
              </li>
            </ul>
          </div>
          <div>
            <h3 class="tw-text-gray-700 tw-uppercase">KEBIJAKAN</h3>
            <ul class="tw-mt-2 tw-space-y-2">
              <li>
                <a href="#" class="tw-text-gray-600 hover:tw-underline"
                  >Kebijakan Privasi</a
                >
              </li>
              <li>
                <a href="#" class="tw-text-gray-600 hover:tw-underline"
                  >Syarat dan Ketentuan Umum</a
                >
              </li>
            </ul>
          </div>
          <div>
            <h3 class="tw-text-gray-700 tw-uppercase">HUBUNGI KAMI</h3>
            <ul class="tw-mt-2 tw-space-y-2">
              <li>
                <a
                  href="mailto:cs@caricatering.id"
                  class="tw-text-gray-600 hover:tw-underline"
                  >cs@caricatering.id</a
                >
              </li>
              <li>
                <a
                  href="tel:+6282334642335"
                  class="tw-text-gray-600 hover:tw-underline"
                  >+6282334642335</a
                >
              </li>
              <li class="tw-flex tw-space-x-4">
                <a href="#" class="tw-text-gray-600 hover:tw-text-blue-500"
                  ><font-awesome-icon
                    icon="fa-brands fa-instagram"
                    class="icon-lg"
                /></a>
                <a href="#" class="tw-text-gray-600 hover:tw-text-blue-400"
                  ><font-awesome-icon
                    icon="fa-brands fa-tiktok"
                    class="icon-lg"
                /></a>
                <a href="#" class="tw-text-gray-600 hover:tw-text-pink-500"
                  ><font-awesome-icon
                    icon="fa-brands fa-youtube"
                    class="icon-lg"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-w-full tw-mt-6 tw-border-t tw-border-gray-200 tw-py-4 tw-text-center"
    >
      <p class="tw-text-gray-500">© 2024 Cari Catering. All rights reserved</p>
    </div>
  </footer>
</template>
