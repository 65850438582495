<script setup>
import { ref, onMounted } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";
const router = useRouter();
const token = ref(null);
onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
});
</script>

<template>
  <div class="tw-flex" id="app">
    <!-- Sidebar -->
    <aside class="tw-w-64 tw-bg-white tw-h-screen tw-p-4">
      <div class="tw-text-2xl tw-font-bold tw-mb-8">Conceals.</div>
      <nav>
        <ul>
          <li class="tw-mb-4">
            <a class="tw-flex tw-items-center tw-text-gray-700" href="#">
              <font-awesome-icon
                class="tw-mr-2 tw-text-black"
                :icon="['fas', 'tags']"
              />
              Overview
            </a>
          </li>
          <li class="tw-mb-4">
            <a class="tw-flex tw-items-center tw-text-gray-700" href="#">
              <font-awesome-icon
                class="tw-mr-2 tw-text-black"
                :icon="['fas', 'tags']"
              />
              Management
            </a>
            <ul class="tw-ml-8 tw-mt-2">
              <li class="tw-mb-2">
                <a class="tw-text-gray-700" href="#"> Member </a>
              </li>
              <li class="tw-mb-2">
                <a class="tw-text-gray-700 tw-font-bold" href="#">
                  Product List
                </a>
              </li>
              <li class="tw-mb-2">
                <a class="tw-text-gray-700" href="#"> Partner List </a>
              </li>
            </ul>
          </li>
          <li class="tw-mb-4">
            <a class="tw-flex tw-items-center tw-text-gray-700" href="#">
              <font-awesome-icon
                class="tw-mr-2 tw-text-black"
                :icon="['fas', 'tags']"
              />
              Analytics
              <span
                class="tw-ml-auto tw-bg-gray-200 tw-text-gray-700 tw-text-sm tw-rounded-full tw-px-2 tw-py-1"
              >
                12
              </span>
            </a>
          </li>
          <li class="tw-mb-4">
            <a class="tw-flex tw-items-center tw-text-gray-700" href="#">
              <font-awesome-icon
                class="tw-mr-2 tw-text-black"
                :icon="['fas', 'tags']"
              />
              Accounting
            </a>
            <ul class="tw-ml-8 tw-mt-2">
              <li class="tw-mb-2">
                <a class="tw-text-gray-700" href="#"> Reports </a>
              </li>
            </ul>
          </li>
          <li class="tw-mb-4">
            <a class="tw-flex tw-items-center tw-text-gray-700" href="#">
              <font-awesome-icon
                class="tw-mr-2 tw-text-black"
                :icon="['fas', 'tags']"
              />
              Settings
            </a>
          </li>
          <li class="tw-mb-4">
            <a class="tw-flex tw-items-center tw-text-gray-700" href="#">
              <font-awesome-icon
                class="tw-mr-2 tw-text-black"
                :icon="['fas', 'tags']"
              />
              Notifications
              <span
                class="tw-ml-auto tw-bg-gray-200 tw-text-gray-700 tw-text-sm tw-rounded-full tw-px-2 tw-py-1"
              >
                20
              </span>
            </a>
          </li>
        </ul>
      </nav>
      <div class="tw-mt-auto">
        <a class="tw-flex tw-items-center tw-text-red-600" href="#">
          <font-awesome-icon
            class="tw-mr-2 tw-text-black"
            :icon="['fas', 'tags']"
          />
          Log Out
        </a>
      </div>
    </aside>
    <!-- Main Content -->
    <main class="tw-flex-1 tw-p-6">
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-6">
        <div class="tw-text-2xl tw-font-bold">6.540 Items</div>
        <div class="tw-flex tw-items-center tw-space-x-4">
          <button
            class="tw-bg-gray-200 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-full tw-flex tw-items-center"
          >
            <font-awesome-icon
              class="tw-mr-2 tw-text-black"
              :icon="['fas', 'tags']"
            />
            Show Statistic
          </button>
          <button
            class="tw-bg-gray-200 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-full tw-flex tw-items-center"
          >
            <font-awesome-icon
              class="tw-mr-2 tw-text-black"
              :icon="['fas', 'tags']"
            />
            Filter
          </button>
          <button
            class="tw-bg-gray-200 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-full tw-flex tw-items-center"
          >
            <font-awesome-icon
              class="tw-mr-2 tw-text-black"
              :icon="['fas', 'tags']"
            />
            List
          </button>
          <button
            class="tw-bg-gray-200 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-full tw-flex tw-items-center"
          >
            <font-awesome-icon
              class="tw-mr-2 tw-text-black"
              :icon="['fas', 'tags']"
            />
            Export
          </button>
          <button
            class="tw-bg-yellow-400 tw-text-white tw-px-4 tw-py-2 tw-rounded-full"
          >
            + New Product
          </button>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-mb-6">
        <div class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
          <div class="tw-text-gray-700">Payment Success</div>
          <div class="tw-text-2xl tw-font-bold">$412.450,99</div>
          <div class="tw-text-red-600">-12,8%</div>
        </div>
        <div class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
          <div class="tw-text-gray-700">Payment Canceled</div>
          <div class="tw-text-2xl tw-font-bold">894</div>
          <div class="tw-text-green-600">+12,4% (+4,32%)</div>
        </div>
        <div class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
          <div class="tw-text-gray-700">Total Customer</div>
          <div class="tw-text-2xl tw-font-bold">2.850</div>
          <div class="tw-text-green-600">+24,6%</div>
        </div>
        <div class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
          <div class="tw-text-gray-700">Total Orders</div>
          <div class="tw-text-2xl tw-font-bold">10.650</div>
          <div class="tw-text-red-600">-14,2% (+$245)</div>
        </div>
      </div>
      <div class="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow">
        <table class="tw-w-full">
          <thead>
            <tr class="tw-text-left tw-text-gray-500">
              <th class="tw-py-2">ITEM NAME</th>
              <th class="tw-py-2">PRICE</th>
              <th class="tw-py-2">STOCK</th>
              <th class="tw-py-2">VOLUME</th>
              <th class="tw-py-2">TYPE</th>
              <th class="tw-py-2">RATING</th>
            </tr>
          </thead>
          <tbody>
            <tr class="tw-border-t">
              <td class="tw-py-2 tw-flex tw-items-center">
                <input class="tw-mr-2" type="checkbox" />
                <img
                  alt="Product image"
                  class="tw-w-10 tw-h-10 tw-mr-2"
                  height="40"
                  src="https://storage.googleapis.com/a1aa/image/qnoJItlfLFQ3NafTAmvuonOVmhjfAXGUbAt9UF0CoOZFpudnA.jpg"
                  width="40"
                />
                <div>
                  <div class="tw-font-bold">Micellar Hyaluronic Aloe Water</div>
                  <div class="tw-text-gray-500">Garnier Skin Active</div>
                </div>
              </td>
              <td class="tw-py-2">$ 40</td>
              <td class="tw-py-2">
                386 Item Left
                <br />
                1.450 Sold
              </td>
              <td class="tw-py-2">400 ml</td>
              <td class="tw-py-2">Sunscreen</td>
              <td class="tw-py-2 tw-flex tw-items-center">
                <font-awesome-icon
                  class="tw-mr-2 tw-text-black"
                  :icon="['fas', 'tags']"
                />
                4.6
                <span class="tw-text-gray-500 tw-ml-1"> 41 Reviews </span>
              </td>
            </tr>
            <!-- Repeat the structure for each row as needed -->
          </tbody>
        </table>
        <div class="tw-flex tw-justify-between tw-items-center tw-mt-4">
          <div class="tw-text-gray-500">Showing 1 of 250</div>
          <div class="tw-flex tw-space-x-4">
            <button
              class="tw-bg-gray-200 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-full"
            >
              Prev
            </button>
            <button
              class="tw-bg-gray-200 tw-text-gray-700 tw-px-4 tw-py-2 tw-rounded-full"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
