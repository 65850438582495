<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { PROD_URL } from "@/utils/url";
import { numFormat } from "@/helpers/index";
import axios from "axios";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import { db } from "@/plugins/firebase";
import {
  collection,
  onSnapshot,
  doc,
  addDoc,
  query,
  orderBy,
  getDocs,
  setDoc
} from "firebase/firestore";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const { proxy } = getCurrentInstance();
const swiperModule = [Pagination, Navigation];
const router = useRouter();
const user = ref(null);
const isDropdownProfile = ref(false);
const token = ref(null);
const packageId = ref(null);
const packages = ref(null);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  packageId.value = router.currentRoute.value.params.id;
  getDetails(packageId.value);
});

async function getDetails(id) {
  await axios
    .get(`${PROD_URL}/api/packages/` + id, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      packages.value = res.data.data.package;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function profileDropdown() {
  isDropdownProfile.value = !isDropdownProfile.value;
}

function doSignOut() {
  axios
    .post(PROD_URL + "/api/auth/logout", "", {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.clear();

      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function gotoOwner() {
  router.push("/owner-home");
}

async function chatOwner() {
  const query = await getDocs(collection(db, "users"));
  let isExistChat = false;
  query.forEach((doc) => {
    if (doc.id == packages.value.catering_id + "," + user.value.user.id) {
      isExistChat = true;
    }
  });
  if (!isExistChat) {
    const parentDocRef = doc(db, "users", packages.value.catering_id + "," + user.value.user.id);
    await setDoc(parentDocRef, { initialized: true }, { merge: true });
    const subcollectionRef = collection(parentDocRef, "chats");
    await addDoc(subcollectionRef, {
        from: "owner",
        text: "Hallo, Apa yang bisa kami bantu?",
        createdAt: new Date(),
    });
  }
  const parentDocRef = doc(db, "users", packages.value.catering_id + "," + user.value.user.id);
  await setDoc(parentDocRef, { initialized: true }, { merge: true });
  const subcollectionRef = collection(parentDocRef, "chats");
  await addDoc(subcollectionRef, {
      from: "customer",
      text: 'Kategori : ' + packages.value.category.id_name + ' ~ ' + 'Paket : ' +packages.value.name + ' ~ ' + 'Harga : ' + 'Rp. ' + proxy.$numFormat(packages.value.price) + ' / paket',
      createdAt: new Date(),
  });
  await addDoc(subcollectionRef, {
      from: "customer",
      text: 'Apakah paket katering ini masih ada?',
      createdAt: new Date(),
  });
  router.push(
    "/chat/customer?chat_id=" + packages.value.catering_id + "," + user.value.user.id
  );
}

async function gotoChat() {
  router.push("/chat/customer");
}

function goBack() {
  router.back();
}
</script>

<template>
  <nav
    class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-white tw-shadow"
  >
    <button
      @click="gotoHome"
      class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4"
    >
      <img
        src="@/assets/caricatering.png"
        alt="Logo"
        class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12"
      />
      <a
        href="#"
        class="tw-text-catering tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg"
        >CariCatering.id</a
      >
    </button>
    <div class="tw-flex tw-items-center tw-space-x-4">
      <div class="tw-relative tw-hidden sm:tw-hidden md:tw-block lg:tw-block">
        <!-- <button
            @click="openDropdown"
            class="tw-flex tw-items-center tw-text-gray-700"
          >
            Cari Apa?
            <span
              class="tw-transition"
              :class="isDropdownCategory ? 'tw-rotate-180' : ''"
            >
              <svg
                class="tw-ml-1 tw-w-4 tw-h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button> -->
        <div
          class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
          :class="isDropdownCategory ? '' : 'tw-hidden'"
          style="z-index: 1"
        >
          <a
            href="#"
            class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
            >Option 1</a
          >
          <a
            href="#"
            class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
            >Option 2</a
          >
          <a
            href="#"
            class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
            >Option 3</a
          >
        </div>
      </div>
      <!-- <a
        href="#"
        class="tw-text-black tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
        >Pusat Bantuan</a
      >
      <a
        href="#"
        class="tw-text-black tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
        >Syarat dan Ketentuan</a
      > -->
      <div v-if="token" class="tw-relative">
        <button
          @click="profileDropdown"
          class="tw-flex tw-items-center tw-text-black"
        >
          Hallo, {{ user?.user?.name }}
          <span
            class="tw-transition"
            :class="isDropdownProfile ? 'tw-rotate-180' : ''"
          >
            <svg
              class="tw-ml-1 tw-w-4 tw-h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
        </button>
        <div
          class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
          :class="isDropdownProfile ? '' : 'tw-hidden'"
          style="z-index: 2"
        >
          <div
            class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
          >
            <font-awesome-icon
              class="tw-pt-1 tw-pr-2"
              :icon="['fas', 'comments']"
            />
            <a @click="gotoChat">Chat</a>
          </div>
          <div
            class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
          >
            <font-awesome-icon
              class="tw-pt-1 tw-pr-3"
              :icon="['fas', 'user']"
            />
            <a @click="gotoOwner">Owner</a>
          </div>
          <div
            class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
          >
            <font-awesome-icon
              class="tw-pt-1 tw-pr-3"
              :icon="['fas', 'sign-out']"
            />
            <a @click="doSignOut">Log Out</a>
          </div>
        </div>
      </div>
      <button
        v-if="!token"
        @click="gotoAuth"
        class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded"
      >
        Masuk
      </button>
    </div>
  </nav>
  <div class="tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32">
    <div
      class="tw-max-w-full tw-mx-auto tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-md"
    >
      <div class="tw-w-full tw-block sm:tw-block md:tw-hidden lg:tw-hidden tw-mb-4">
        <div @click="goBack" class="tw-flex">
          <font-awesome-icon
            class="tw-text-black tw-pr-2 tw-pt-1.5"
            size="lg"
            :icon="['fas', 'chevron-left']"
          />
          <h2 class="tw-text-xl tw-font-semibold tw-text-black tw-mb-3">
            Kembali
          </h2>
        </div>
        <swiper
          class="swiper-backface-hidden tw-h-[35vh] sm:tw-h-[35vh] md:tw-h-[70vh] lg:tw-h-[70vh]"
          id="product_photos_swiper"
          :pagination="{
            dynamicBullets: true,
          }"
          :modules="swiperModule"
        >
          <swiper-slide
            v-for="(image, imageIndex) in packages?.images"
            :key="imageIndex"
          >
            <div class="tw-flex tw-justify-center tw-h-[35vh] sm:tw-h-[35vh] md:tw-h-[70vh] lg:tw-h-[70vh]">
              <img
                :src="image.url_path"
                alt=""
                class="tw-object-contain tw-rounded-lg tw-h-[35vh] sm:tw-h-[35vh] md:tw-h-[70vh] lg:tw-h-[70vh]"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="tw-block">
        <div class="tw-w-full tw-mr-4 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">
          <div @click="goBack" class="tw-flex">
            <font-awesome-icon
              class="tw-text-black tw-pr-2 tw-pt-1.5"
              size="lg"
              :icon="['fas', 'chevron-left']"
            />
            <h2 class="tw-text-xl tw-font-semibold tw-text-black tw-mb-3">
              Kembali
            </h2>
          </div>
          <swiper
            class="swiper-backface-hidden tw-h-[35vh] sm:tw-h-[35vh] md:tw-h-[70vh] lg:tw-h-[70vh]"
            id="product_photos_swiper"
            :pagination="{
              dynamicBullets: true,
            }"
            :modules="swiperModule"
          >
            <swiper-slide
              v-for="(image, imageIndex) in packages?.images"
              :key="imageIndex"
            >
              <div class="tw-flex tw-justify-center tw-h-[35vh] sm:tw-h-[35vh] md:tw-h-[70vh] lg:tw-h-[70vh]">
                <img
                  :src="image.url_path"
                  alt=""
                  class="tw-object-contain tw-rounded-lg tw-h-[35vh] sm:tw-h-[35vh] md:tw-h-[70vh] lg:tw-h-[70vh]"
                />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="tw-w-full tw-mt-4">
          <div class="tw-text-sm tw-text-gray-500 tw-mb-2">
            {{ packages?.category.en_name }} / {{ packages?.category.id_name }}
          </div>
          <h1 class="tw-text-3xl tw-font-bold tw-mb-4 tw-text-black">
            {{ packages?.name }}
          </h1>
          <div class="tw-flex tw-items-center tw-mb-4">
            <span class="tw-text-2xl tw-font-bold tw-text-gray-900">
              Rp. {{ $numFormat(packages?.price) }}
            </span>
            <span class="tw-ml-1 tw-text-gray-500 tw-text-sm"> / paket </span>
          </div>
          <div class="tw-mb-2">
            <h2 class="tw-text-lg tw-font-semibold">Deskripsi</h2>
            <p class="tw-text-gray-700">
              {{ packages?.description }}
            </p>
          </div>
          <div class="tw-mb-2">
            <h2 class="tw-text-lg tw-font-semibold tw-mb-1">
              Pilihan Menu Paket
            </h2>
            <div class="tw-flex">
              <span
                class="tw-mr-2 tw-px-2 tw-py-1 tw-bg-gray-200 tw-text-gray-700 tw-text-sm tw-rounded"
              >
                Minimal {{ packages?.min_menu_selection }} menu
              </span>
              <p class="tw-text-gray-700">-</p>
              <span
                class="tw-ml-2 tw-px-2 tw-py-1 tw-bg-gray-200 tw-text-gray-700 tw-text-sm tw-rounded"
              >
                Maksimal {{ packages?.max_menu_selection }} menu
              </span>
            </div>
            <div
              class="tw-flex tw-pt-2"
              v-for="(menu, menuIndex) in packages?.menus"
              :key="menuIndex"
            >
              <font-awesome-icon
                class="tw-pt-2 tw-mr-2 tw-text-catering"
                :icon="['fas', 'tags']"
              />
              <div class="tw-flex tw-flex-col">
                <p class="tw-text-gray-700 tw-text-base">
                  {{ menu.name }}
                </p>
                <span class="tw-text-gray-400 tw-text-sm">
                  {{ menu.description }}
                </span>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-space-x-4 tw-mt-8">
            <!-- <button
              class="tw-flex-1 tw-py-3 tw-bg-gray-300 tw-text-black tw-rounded-lg"
            >
              Tambah ke Favorit
            </button> -->
            <button
              @click="chatOwner"
              class="tw-flex-1 tw-py-3 tw-bg-catering tw-text-white tw-rounded-lg"
            >
              Tanyakan Langsung
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
