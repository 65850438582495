<script setup>
import { ref, onMounted } from "vue";
// import { PROD_URL } from "@/utils/url"
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);
const user = ref(null);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
});

function gotoHome() {
  router.push("/home");
}

function gotoCateringHome() {
  router.push("/owner-catering");
}

function gotoPromotionCatering() {
  router.push("/promotion-catering");
}

function gotoPremiumPage() {
  router.push("/premium-page");
}
</script>

<template>
  <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
    <aside class="tw-w-64 tw-bg-white tw-text-black">
      <nav>
        <div
          class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white"
        >
          <div class="tw-px-4 tw-py-6">
            <div
              class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100"
            >
              <a
                href="#"
                class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50"
              >
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  class="tw-size-10 tw-rounded-full tw-object-cover"
                />

                <div>
                  <p class="tw-text-xs">
                    <strong class="tw-block tw-text-black tw-font-medium"
                      >Hallo,</strong
                    >

                    <span class="tw-text-black">{{ user?.user?.name }}</span>
                  </p>
                </div>
              </a>
            </div>
            <ul class="tw-mt-6 tw-space-y-1">
              <li>
                <button
                  href="#"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-bg-gray-100 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-catering"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  @click="gotoCateringHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Catering Saya
                </button>
              </li>
              <li>
                <details
                  class="tw-group [&_summary::-webkit-details-marker]:tw-hidden"
                >
                  <summary
                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                  >
                    <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                    <span
                      class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-size-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>
                  <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                    <li>
                      <button
                        @click="gotoPromotionCatering()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Highlight Catering
                      </button>
                    </li>
                    <li>
                      <button
                        @click="gotoPremiumPage()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Halaman Premium Catering
                      </button>
                    </li>
                    <li>
                      <button
                        href="#"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Foto dan Desain Catering
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <button
                  @click="gotoHome"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-red-100 hover:tw-text-red-600"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>

    <section class="tw-bg-white tw-bg-gray-100 tw-w-full">
      <div class="tw-container tw-p-8">
        <div class="tw-flex">
          <img
            src="@/assets/caricatering.png"
            class="tw-mr-2 tw-h-8 sm:tw-h-8 md:tw-h-10 lg:tw-h-10"
          />
          <h1
            class="tw-text-2xl tw-font-semibold tw-text-gray-800 tw-capitalize lg:tw-text-3xl tw-text-black"
          >
            Hallo, {{ user?.user?.name }}
          </h1>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 tw-gap-8 tw-mt-8 xl:tw-mt-8 xl:tw-gap-12 md:tw-grid-cols-2 xl:tw-grid-cols-2"
        >
          <div
            class="tw-p-4 tw-space-y-3 tw-border-2 tw-border-catering tw-border-catering tw-rounded-xl"
          >
            <div class="tw-flex tw-justify-between">
              <div class="tw-flex">
                <span
                  class="tw-mr-2 tw-inline-block tw-text-catering tw-text-catering"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="tw-w-8 tw-h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                    />
                  </svg>
                </span>
                <h1
                  class="tw-text-xl tw-font-semibold tw-text-catering tw-capitalize tw-text-black"
                >
                  elegant Dark Mode
                </h1>
              </div>
              <!-- <a
                href="#"
                class="tw-h-fit tw-inline-flex tw-p-2 tw-text-catering tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-full rtl:tw-scale-x-100 tw-bg-catering tw-text-white hover:tw-underline hover:tw-text-catering hover:tw-text-catering"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="tw-w-6 tw-h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a> -->
            </div>
            <img
              alt=""
              src="@/assets/profile1.jpeg"
              class="tw-w-full tw-object-cover"
            />
            <button @click="gotoPremiumPage"
              class="tw-flex tw-mt-4 tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-catering focus:tw-outline-none focus:tw-ring focus:tw-ring-catering focus:tw-ring-opacity-80"
            >
              <font-awesome-icon
                class="tw-text-white tw-pr-1"
                size="lg"
                :icon="['fas', 'paper-plane']"
              />
              <span class="tw-mx-1">Request Profile Page Sekarang</span>
            </button>
          </div>
          <div
            class="tw-p-4 tw-space-y-3 tw-border-2 tw-border-catering tw-border-catering tw-rounded-xl"
          >
            <div class="tw-flex tw-justify-between">
              <div class="tw-flex">
                <span
                  class="tw-mr-2 tw-inline-block tw-text-catering tw-text-catering"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="tw-w-8 tw-h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                  </svg>
                </span>
                <h1
                  class="tw-text-xl tw-font-semibold tw-text-catering tw-capitalize tw-text-black"
                >
                  Simple & clean designs
                </h1>
              </div>
              <!-- <a
                href="#"
                class="tw-h-fit tw-inline-flex tw-p-2 tw-text-catering tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-full rtl:tw-scale-x-100 tw-bg-catering tw-text-white hover:tw-underline hover:tw-text-catering hover:tw-text-catering"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="tw-w-6 tw-h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a> -->
            </div>
            <img
              alt=""
              src="@/assets/profile2.jpeg"
              class="tw-w-full tw-object-cover"
            />
            <button @click="gotoPremiumPage"
              class="tw-flex tw-mt-4 tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-catering focus:tw-outline-none focus:tw-ring focus:tw-ring-catering focus:tw-ring-opacity-80"
            >
              <font-awesome-icon
                class="tw-text-white tw-pr-1"
                size="lg"
                :icon="['fas', 'paper-plane']"
              />
              <span class="tw-mx-1">Request Profile Page Sekarang</span>
            </button>
          </div>
          <div
            class="tw-p-4 tw-space-y-3 tw-border-2 tw-border-catering tw-border-catering tw-rounded-xl"
          >
            <div class="tw-flex tw-justify-between">
              <div class="tw-flex">
                <span
                  class="tw-mr-2 tw-inline-block tw-text-catering tw-text-catering"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="tw-w-8 tw-h-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                  </svg>
                </span>
                <h1
                  class="tw-text-xl tw-font-semibold tw-text-catering tw-capitalize tw-text-black"
                >
                  Beautiful & colorfull designs
                </h1>
              </div>
              <!-- <a
                href="#"
                class="tw-h-fit tw-inline-flex tw-p-2 tw-text-catering tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-full rtl:tw-scale-x-100 tw-bg-catering tw-text-white hover:tw-underline hover:tw-text-catering hover:tw-text-catering"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="tw-w-6 tw-h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a> -->
            </div>
            <img
              alt=""
              src="@/assets/profile3.jpeg"
              class="tw-w-full tw-object-cover"
            />
            <button @click="gotoPremiumPage"
              class="tw-flex tw-mt-4 tw-items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-catering focus:tw-outline-none focus:tw-ring focus:tw-ring-catering focus:tw-ring-opacity-80"
            >
              <font-awesome-icon
                class="tw-text-white tw-pr-1"
                size="lg"
                :icon="['fas', 'paper-plane']"
              />
              <span class="tw-mx-1">Request Profile Page Sekarang</span>
            </button>
          </div>
        </div>
      </div>

      <!-- <div
        class="tw-px-8 tw-grid tw-grid-cols-1 tw-gap-8 tw-mt-2 xl:tw-mt-2 xl:tw-gap-12 md:tw-grid-cols-2 xl:tw-grid-cols-2"
      >
        <div class="tw-bg-gray-100 tw-w-full">
          <div class="tw-bg-white tw-p-6 tw-rounded-xl tw-shadow tw-w-full">
            <h1 class="tw-text-xl tw-font-semibold tw-text-gray-800 tw-mb-2">
              Tingkatkan Kinerja Catering
            </h1>
            <p class="tw-text-gray-600 tw-mb-4">
              Lengkapi Catering Anda dengan fitur berikut.
            </p>
            <div class="tw-space-y-4">
              <div
                class="tw-flex tw-items-center tw-justify-between tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-shadow-sm"
              >
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <div
                    class="tw-h-fit tw-p-2 tw-bg-catering tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-w-6 tw-h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="tw-font-semibold tw-text-gray-800">
                      Catering Foto
                    </p>
                    <p class="tw-text-gray-600 tw-text-sm">
                      Sewa jasa foto Catering profesional
                    </p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-chevron-right tw-text-gray-400"></i>
                </div>
              </div>
              <div
                class="tw-flex tw-items-center tw-justify-between tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-shadow-sm"
              >
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <div
                    class="tw-h-fit tw-p-2 tw-bg-catering tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-w-6 tw-h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="tw-font-semibold tw-text-gray-800">
                      Catering Website
                    </p>
                    <p class="tw-text-gray-600 tw-text-sm">
                      Halaman Premium Catering
                    </p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-chevron-right tw-text-gray-400"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-bg-gray-100 tw-w-full">
          <div class="tw-bg-white tw-p-6 tw-rounded-xl tw-shadow tw-w-full">
            <h1 class="tw-text-xl tw-font-semibold tw-text-gray-800 tw-mb-2">
              Tingkatkan Kinerja Catering
            </h1>
            <p class="tw-text-gray-600 tw-mb-4">
              Lengkapi Catering Anda dengan fitur berikut.
            </p>
            <div class="tw-space-y-4">
              <div
                class="tw-flex tw-items-center tw-justify-between tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-shadow-sm"
              >
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <div
                    class="tw-h-fit tw-p-2 tw-bg-catering tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-w-6 tw-h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="tw-font-semibold tw-text-gray-800">
                      Catering Foto
                    </p>
                    <p class="tw-text-gray-600 tw-text-sm">
                      Sewa jasa foto Catering profesional
                    </p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-chevron-right tw-text-gray-400"></i>
                </div>
              </div>
              <div
                class="tw-flex tw-items-center tw-justify-between tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-shadow-sm"
              >
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <div
                    class="tw-h-fit tw-p-2 tw-bg-catering tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-w-6 tw-h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p class="tw-font-semibold tw-text-gray-800">
                      Catering Website
                    </p>
                    <p class="tw-text-gray-600 tw-text-sm">
                      Halaman Premium Catering
                    </p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-chevron-right tw-text-gray-400"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </section>
  </div>
</template>
