<script setup>
import { ref, onMounted } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const token = ref(null);
const isLoading = ref(false);
const categories = ref([]);
const selectedCategory = ref(null);
const inputs = ref([{ name: "", description: "" }]);
const formData = ref({
  name: "",
  price: null,
  description: "",
  min_menu: null,
  max_menu: null,
});
const selectedImages = ref([]);
const imageUrls = ref([]);
const user = ref(null)

const addInput = () => {
  inputs.value.push({ name: "", description: "" });
};

async function handleSubmit() {
  console.log(inputs.value);
  console.log(selectedCategory.value);
  console.log(formData.value);

  if (!isLoading.value) {
    isLoading.value = true;
    const formDataSubmit = {
      catering_id: localStorage.getItem("selectedOutletId"),
      category_id: selectedCategory.value.id,
      name: formData.value.name,
      price: formData.value.price,
      description: formData.value.description,
      min_menu_selection: formData.value.min_menu,
      max_menu_selection: formData.value.max_menu,
      images: selectedImages.value,
      menus: inputs.value,
    };
    let editId = "";
    if (route.query.id) {
      formDataSubmit._method = "PUT";
      editId = "/" + route.query.id;
    }
    await axios
      .post(`${PROD_URL}/api/owner/packages` + editId, formDataSubmit, {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        isLoading.value = false;
        gotoCateringHome();
      })
      .catch((err) => {
        isLoading.value = false;
        if (err.response.status == 401) {
          router.push("/auth");
        }
      });
  }
}

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  if (route.query.id) {
    getDetails(route.query.id);
  }
  getcategories();
});

async function getDetails(id) {
  await axios
    .get(`${PROD_URL}/api/owner/packages/` + id, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      const data = res.data.data.package;
      formData.value.name = data.name;
      formData.value.price = data.price;
      formData.value.description = data.description;
      formData.value.min_menu = data.min_menu_selection;
      formData.value.max_menu = data.max_menu_selection;
      selectedCategory.value = data.category;
      inputs.value = data.menus;
      data.images.forEach((v) => {
        imageUrls.value.push(v.url_path);
      });
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function onImageChange(event) {
  if (selectedImages.value.length == 0) {
    imageUrls.value.length = 0;
  }
  selectedImages.value.push(event.target.files[0]);
  if (event.target.files[0]) {
    imageUrls.value.push(URL.createObjectURL(event.target.files[0]));
  }
}

async function removeImage(index) {
  if (route.query.id) {
    imageUrls.value = [];
  } else {
    selectedImages.value.splice(index, 1);
    imageUrls.value.splice(index, 1);
  }
}

async function getcategories() {
  await axios
    .get(`${PROD_URL}/api/categories`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      categories.value = res.data.data.categories;
      console.log(categories.value);
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function gotoCateringHome() {
  router.push("/owner-catering");
}

function gotoOwnerHome() {
  router.push("/owner-home");
}

function gotoHome() {
  router.push("/home");
}

function gotoPromotionCatering() {
  router.push("/promotion-catering");
}

function gotoPremiumPage() {
  router.push("/premium-page");
}
</script>

<template>
  <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
    <aside class="tw-w-64 tw-bg-white tw-text-black">
      <nav>
        <div
          class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white"
        >
          <div class="tw-px-4 tw-py-6">
            <div
              class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100"
            >
              <a
                href="#"
                class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50"
              >
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  class="tw-size-10 tw-rounded-full tw-object-cover"
                />

                <div>
                  <p class="tw-text-xs">
                    <strong class="tw-block tw-text-black tw-font-medium"
                      >Hallo,</strong
                    >

                    <span class="tw-text-black">{{ user?.user?.name }}</span>
                  </p>
                </div>
              </a>
            </div>
            <ul class="tw-mt-6 tw-space-y-1">
              <li>
                <button
                  @click="gotoOwnerHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  @click="gotoCateringHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Catering Saya
                </button>
              </li>
              <li>
                <details
                  class="tw-group [&_summary::-webkit-details-marker]:tw-hidden"
                >
                  <summary
                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                  >
                    <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                    <span
                      class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-size-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>
                  <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                    <li>
                      <button
                        @click="gotoPromotionCatering()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Highlight Catering
                      </button>
                    </li>
                    <li>
                      <button
                        @click="gotoPremiumPage()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Halaman Premium Catering
                      </button>
                    </li>
                    <li>
                      <button
                        href="#"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Foto dan Desain Catering
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <button
                  @click="gotoHome"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-red-100 hover:tw-text-red-600"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>

    <div
      class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12"
    >
      <div
        class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <h2
          class="tw-sm:text-3xl tw-text-2xl tw-leading-6 tw-font-bold tw-text-center tw-text-black tw-mb-4"
        >
          Buat Paket
        </h2>
        <form>
          <div class="">
            <h3 class="tw-md:text-xl tw-text-sm">Foto - Foto Menu Paket</h3>
            <div class="tw-block tw-w-full tw-items-center tw-justify-center">
              <div
                class="tw-flex tw-flex-row tw-overflow-x-auto tw-space-x-4 tw-w-full"
              >
                <div
                  class="tw-shrink-0"
                  v-for="(image, imageIndex) in imageUrls"
                  :key="imageIndex"
                >
                  <img
                    class="tw-mt-2 tw-mr-2 tw-w-36 tw-h-40"
                    :src="image"
                    alt="Selected image"
                  />
                  <div
                    @click="removeImage(imageIndex)"
                    class="tw-relative -tw-top-40 tw-text-right tw-pt-2 tw-pr-4"
                  >
                    <font-awesome-icon
                      class="tw-opacity-70 tw-text-gray-300 fa-2xl"
                      :icon="['fas', 'circle-xmark']"
                    />
                  </div>
                </div>
                <label
                  for="dropzone-file"
                  class="tw-mt-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-36 tw-h-40 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 tw-hover:bg-gray-100"
                >
                  <div
                    class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6 tw-w-36 tw-h-40"
                  >
                    <svg
                      class="tw-w-8 tw-h-8 tw-mb-4 tw-text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h3"
                      />
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m9 11 2 2 4-4M12 15v-7"
                      />
                    </svg>
                    <p class="tw-mb-2 tw-text-sm tw-text-gray-500">
                      <span class="tw-font-semibold">Click to upload</span>
                    </p>
                    <p class="tw-text-xs tw-text-gray-500">(MAX. 1mb)</p>
                  </div>
                  <input
                    @change="onImageChange"
                    id="dropzone-file"
                    type="file"
                    class="tw-hidden"
                    accept="image/*"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="">
            <h3 class="tw-mb-3 tw-md:text-xl tw-text-sm">Kategori Paket</h3>
            <select
              id="category"
              v-model="selectedCategory"
              class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500"
            >
              <option selected disabled class="tw-text-gray-300">
                Pilih Kategori
              </option>
              <option
                :value="category"
                v-for="(category, categoryIndex) in categories"
                :key="categoryIndex"
              >
                {{ category.id_name }}
              </option>
            </select>
          </div>
          <div class="tw-mt-2">
            <label
              for="name"
              class="tw-block tw-text-sm tw-font-medium tw-leading-6"
            >
              Nama Paket
            </label>
            <div class="tw-mt-2">
              <input
                id="name"
                type="text"
                v-model="formData.name"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                required
              />
            </div>
          </div>
          <div class="tw-mt-2">
            <label
              for="price"
              class="tw-block tw-text-sm tw-font-medium tw-leading-6"
            >
              Harga Paket
            </label>
            <div class="tw-mt-2">
              <input
                id="price"
                type="number"
                v-model="formData.price"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                required
              />
            </div>
          </div>
          <div class="tw-mt-2">
            <label
              for="message"
              class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >Deskripsi Paket</label
            >
            <textarea
              v-model="formData.description"
              id="message"
              class="tw-block tw-p-2.5 tw-max-h-20 tw-min-h-[80px] tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-bottom-0 tw-rounded-lg tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-primary tw-focus:ring-2 tw-focus:ring-inset tw-transition-all tw-duration-500"
            ></textarea>
          </div>
          <div class="tw-flex tw-flex-row">
            <div class="tw-mt-2 tw-mr-2 tw-w-1/2">
              <label
                for="max"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >
                Minimal Pilihan Menu
              </label>
              <div class="tw-mt-2">
                <input
                  id="max"
                  type="number"
                  v-model="formData.min_menu"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                  required
                />
              </div>
            </div>
            <div class="tw-mt-2 tw-ml-2 tw-w-1/2">
              <label
                for="min"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >
                Maksimal Pilihan Menu
              </label>
              <div class="tw-mt-2">
                <input
                  id="min"
                  type="number"
                  v-model="formData.max_menu"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                  required
                />
              </div>
            </div>
          </div>
          <div v-for="(input, index) in inputs" :key="index" class="my-2">
            <label
              :for="'menu-' + (index + 1)"
              class="tw-block tw-text-sm tw-font-medium tw-leading-6"
            >
              Nama Menu {{ index + 1 }}
            </label>
            <div class="tw-mt-2">
              <input
                type="text"
                v-model="inputs[index].name"
                :id="'menu-' + (index + 1)"
                :name="'menu-' + (index + 1)"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                required
              />
            </div>
            <label
              :for="'desc-' + (index + 1)"
              class="tw-block tw-text-sm tw-font-medium tw-leading-6"
            >
              Deskripsi Menu {{ index + 1 }}
            </label>
            <div class="tw-mt-2">
              <input
                type="text"
                v-model="inputs[index].description"
                :id="'desc-' + (index + 1)"
                :name="'desc-' + (index + 1)"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                required
              />
            </div>
          </div>
          <div class="tw-flex tw-justify-end tw-w-full">
            <button
              type="button"
              @click="addInput"
              class="tw-mt-4 tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md"
            >
              Tambah Menu
            </button>
          </div>
          <div
            class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8"
          >
            <button
              @click="handleSubmit()"
              type="button"
              class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500"
            >
              Submit
            </button>
            <button
              @click="gotoCateringHome()"
              type="button"
              class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
