<script setup>
import { ref, onMounted } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const token = ref(null);
const isLoading = ref(false);
const cateringId = ref(null);
const selectedImage = ref(null);
const formData = ref({
  name: "",
  email: "",
  phone_number: "",
  description: "",
  address: "",
});
const imageUrl = ref(null);
const categories = ref([]);
const provinces = ref([]);
const cities = ref([]);
const districts = ref([]);
const villages = ref([]);
const selectedProvince = ref(null);
const selectedCity = ref(null);
const selectedDistrict = ref(null);
const selectedVillage = ref(null);
const selectedCategories = ref([]);
const user = ref(null)

async function urlToFile(url, filename) {
  const blob = fetch(
    "https://apidev.caricatering.id/caterings/IMG-20241106125739-YFMS3M.jpg"
  )
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    });

  return new File([blob], filename, { type: "image/jpeg" });
}

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  if (route.query.id) {
    getDetails(route.query.id);
    cateringId.value = route.query.id;
  } else {
    getProvinces();
  }
});

function onImageChange(event) {
  selectedImage.value = event.target.files[0];
  if (selectedImage.value) {
    imageUrl.value = URL.createObjectURL(selectedImage.value);
  }
}

async function getDetails(id) {
  await axios
    .get(`${PROD_URL}/api/owner/caterings/` + id, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      const data = res.data.data.catering;
      formData.value.name = data.name;
      formData.value.email = data.email;
      formData.value.phone_number = data.phone_number;
      formData.value.description = data.description;
      formData.value.address = data.address;
      selectedProvince.value = data.province;
      selectedCity.value = data.city;
      selectedDistrict.value = data.district;
      selectedVillage.value = data.village;
      getProvinces(true);
      getCities(true);
      getDistricts(true);
      getVillages(true);
      data.categories.forEach((v) => {
        selectedCategories.value.push(v);
      });
      imageUrl.value = data.image.url_path;
      // urlToFile(
      //   data.image.url_path,
      //   "image.jpg"
      // ).then((value) => {
      //   imageUrl.value = URL.createObjectURL(value)
      //   selectedImage.value = value
      // });
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getProvinces(isEdit = false) {
  if (!isEdit) {
    selectedProvince.value = null;
    selectedCity.value = null;
    selectedDistrict.value = null;
    selectedVillage.value = null;
  }
  await axios
    .get(`${PROD_URL}/api/regions/provinces`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      provinces.value = res.data.data.provinces;
      getcategories();
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getCities(isEdit = false) {
  if (!isEdit) {
    selectedCity.value = null;
    selectedDistrict.value = null;
    selectedVillage.value = null;
  }
  await axios
    .get(
      `${PROD_URL}/api/regions/cities?province_code=` +
        selectedProvince.value.code,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    )
    .then((res) => {
      cities.value = res.data.data.cities;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getDistricts(isEdit = false) {
  if (!isEdit) {
    selectedDistrict.value = null;
    selectedVillage.value = null;
  }
  await axios
    .get(
      `${PROD_URL}/api/regions/districts?city_code=` + selectedCity.value.code,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    )
    .then((res) => {
      districts.value = res.data.data.districts;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getVillages(isEdit = false) {
  if (!isEdit) {
    selectedVillage.value = null;
  }
  await axios
    .get(
      `${PROD_URL}/api/regions/villages?district_code=` +
        selectedDistrict.value.code,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    )
    .then((res) => {
      villages.value = res.data.data.villages;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getcategories() {
  await axios
    .get(`${PROD_URL}/api/categories`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      categories.value = res.data.data.categories;
      selectedCategories.value.forEach((v) => {
        categories.value.forEach((category) => {
          if (category.id == v.id) {
            category.isSelected = true;
          }
        });
      });
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function handleSubmit() {
  if (!isLoading.value) {
    isLoading.value = true;
    const selectedCategory = [];
    categories.value.forEach((category) => {
      if (category.isSelected == true) {
        selectedCategory.push(category.id);
      }
    });
    const formDataSubmit = {
      name: formData.value.name,
      email: formData.value.email,
      phone_number: formData.value.phone_number,
      description: formData.value.description,
      address: formData.value.address,
      province_id: selectedProvince.value.id,
      city_id: selectedCity.value.id,
      district_id: selectedDistrict.value.id,
      village_id: selectedVillage.value.id,
      categories: selectedCategory,
    };
    let editId = "";
    if (selectedImage.value) {
      formDataSubmit.image = selectedImage.value;
    }
    if (cateringId.value) {
      formDataSubmit._method = "PUT";
      editId = "/" + cateringId.value;
    }
    console.log(formDataSubmit);

    await axios
      .post(`${PROD_URL}/api/owner/caterings` + editId, formDataSubmit, {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        isLoading.value = false;
        gotoCateringHome();
      })
      .catch((err) => {
        isLoading.value = false;
        if (err.response.status == 401) {
          router.push("/auth");
        }
      });
  }
}

function selectCategory(id) {
  categories.value.forEach((category) => {
    if (category.id == id) {
      category.isSelected = !category.isSelected;
    }
  });
}

function gotoCateringHome() {
  router.push("/owner-catering");
}

function gotoOwnerHome() {
  router.push("/owner-home");
}

function gotoHome() {
  router.push("/home");
}

function gotoPromotionCatering() {
  router.push("/promotion-catering");
}

function gotoPremiumPage() {
  router.push("/premium-page");
}
</script>

<template>
  <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
    <aside class="tw-w-64 tw-bg-white tw-text-black">
      <nav>
        <div
          class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white"
        >
          <div class="tw-px-4 tw-py-6">
            <div
              class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100"
            >
              <a
                href="#"
                class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50"
              >
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  class="tw-size-10 tw-rounded-full tw-object-cover"
                />

                <div>
                  <p class="tw-text-xs">
                    <strong class="tw-block tw-text-black tw-font-medium"
                      >Hallo,</strong
                    >

                    <span class="tw-text-black">{{ user?.user?.name }}</span>
                  </p>
                </div>
              </a>
            </div>
            <ul class="tw-mt-6 tw-space-y-1">
              <li>
                <button
                  @click="gotoOwnerHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  @click="gotoCateringHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Catering Saya
                </button>
              </li>
              <li>
                <details
                  class="tw-group [&_summary::-webkit-details-marker]:tw-hidden"
                >
                  <summary
                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                  >
                    <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                    <span
                      class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-size-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>
                  <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                    <li>
                      <button
                        @click="gotoPromotionCatering()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Highlight Catering
                      </button>
                    </li>
                    <li>
                      <button
                        @click="gotoPremiumPage()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Halaman Premium Catering
                      </button>
                    </li>
                    <li>
                      <button
                        href="#"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Foto dan Desain Catering
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <button
                  @click="gotoHome"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-red-100 hover:tw-text-red-600"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>

    <div
      class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12"
    >
      <div
        class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <h2
          class="tw-sm:text-4xl tw-text-3xl tw-leading-6 tw-font-bold tw-text-center tw-text-primary tw-mb-4"
        >
          WORK WITH US
        </h2>
        <p
          class="tw-sm:text-lg tw-text-base tw-text-center tw-sm:mb-12 tw-mb-6 tw-text-gray-500"
        >
          NOW, THAT'S SOME SMART THINKING.
        </p>
        <form>
          <label
            class="tw-block tw-mt-8 tw-text-sm tw-font-medium tw-leading-6"
          >
            Foto Logo Catering
          </label>
          <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
            <label
              for="dropzone-file"
              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-40 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 tw-hover:bg-gray-100"
            >
              <img
                class="tw-w-36 tw-h-36"
                :src="imageUrl"
                v-if="imageUrl"
                alt="Selected image"
              />
              <div
                v-else
                class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6"
              >
                <svg
                  class="tw-w-8 tw-h-8 tw-mb-4 tw-text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h3"
                  />
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m9 11 2 2 4-4M12 15v-7"
                  />
                </svg>
                <p class="tw-mb-2 tw-text-sm tw-text-gray-500">
                  <span class="tw-font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p class="tw-text-xs tw-text-gray-500">PNG dan JPG(MAX. 1mb)</p>
              </div>
              <input
                @change="onImageChange"
                id="dropzone-file"
                type="file"
                class="tw-hidden"
                accept="image/*"
              />
            </label>
          </div>
          <div
            class="tw-grid tw-sm:grid-cols-2 tw-md:gap-8 tw-gap-6 tw-text-slate-500"
          >
            <div>
              <label
                for="name"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >
                Nama Catering
              </label>
              <div class="tw-mt-2">
                <input
                  v-model="formData.name"
                  id="name"
                  type="text"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                  required
                />
              </div>
            </div>
            <div>
              <label
                for="email"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >
                Email Catering
              </label>
              <div class="tw-mt-2">
                <input
                  v-model="formData.email"
                  id="email"
                  type="text"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                  required
                />
              </div>
            </div>
            <div>
              <label
                for="phone"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >
                No Whatsapp
              </label>
              <div class="tw-mt-2">
                <input
                  v-model="formData.phone_number"
                  id="phone"
                  type="text"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                  required
                />
              </div>
            </div>
            <div>
              <label
                for="message"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
                >Tentang Kami</label
              >
              <textarea
                v-model="formData.description"
                id="message"
                class="tw-block tw-p-2.5 tw-max-h-20 tw-min-h-[80px] tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-bottom-0 tw-rounded-lg tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-primary tw-focus:ring-2 tw-focus:ring-inset tw-transition-all tw-duration-500"
              ></textarea>
            </div>
            <div>
              <label
                for="address"
                class="tw-block tw-text-sm tw-font-medium tw-leading-6"
              >
                Alamat Catering
              </label>
              <div class="tw-mt-2">
                <input
                  v-model="formData.address"
                  id="address"
                  type="text"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
                  required
                />
              </div>
            </div>
            <div>
              <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">Provinsi</h3>
              <select
                v-model="selectedProvince"
                @change="getCities"
                class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500"
              >
                <option selected disabled class="tw-text-gray-300">
                  Pilih Provinsi
                </option>
                <option
                  :value="province"
                  v-for="(province, provinceIndex) in provinces"
                  :key="provinceIndex"
                >
                  {{ province.name }}
                </option>
              </select>
            </div>
            <div v-if="selectedProvince != null">
              <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">Kabupaten / Kota</h3>
              <select
                v-model="selectedCity"
                @change="getDistricts"
                class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500"
              >
                <option selected disabled class="tw-text-gray-300">
                  Pilih Kabupaten / Kota
                </option>
                <option
                  :value="city"
                  v-for="(city, cityIndex) in cities"
                  :key="cityIndex"
                >
                  {{ city.name }}
                </option>
              </select>
            </div>
            <div v-if="selectedCity != null">
              <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">Kecamatan</h3>
              <select
                v-model="selectedDistrict"
                @change="getVillages"
                class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500"
              >
                <option selected disabled class="tw-text-gray-300">
                  Pilih Kecamatan
                </option>
                <option
                  :value="district"
                  v-for="(district, districtIndex) in districts"
                  :key="districtIndex"
                >
                  {{ district.name }}
                </option>
              </select>
            </div>
            <div v-if="selectedDistrict != null">
              <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">Desa / Dusun</h3>
              <select
                v-model="selectedVillage"
                class="tw-bg-gray-50 tw-text-gray-600 tw-text-sm tw-rounded-md tw-block tw-w-full tw-px-2.5 tw-py-3.5 tw-border-0 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-primary tw-transition-all tw-duration-500"
              >
                <option selected disabled class="tw-text-gray-300">
                  Pilih Desa / Dusun
                </option>
                <option
                  :value="village"
                  v-for="(village, villageIndex) in villages"
                  :key="villageIndex"
                >
                  {{ village.name }}
                </option>
              </select>
            </div>
            <div>
              <h3 class="tw-mb-4 tw-md:text-xl tw-text-sm">Layanan</h3>
              <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                <div
                  class="tw-flex tw-items-center"
                  v-for="(category, categoryIndex) in categories"
                  :key="categoryIndex"
                >
                  <input
                    type="checkbox"
                    @click="selectCategory(category.id)"
                    :id="category.id"
                    v-model="category.isSelected"
                    class="tw-w-4 tw-h-4 tw-text-primary tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-accent-primary"
                  />
                  <label
                    :for="category.id"
                    class="tw-ml-2 tw-pt-1.5 tw-text-sm tw-font-medium tw-text-slate-500"
                  >
                    {{ category.id_name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8"
          >
            <button
              @click="handleSubmit()"
              type="button"
              class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500"
            >
              Submit
            </button>
            <button
              @click="gotoCateringHome()"
              type="button"
              class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
