<script setup>
import { ref, onMounted } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import { db } from "@/plugins/firebase";
import {
  collection,
  onSnapshot,
  doc,
  addDoc,
  query,
  orderBy,
  getDocs,
  setDoc,
} from "firebase/firestore";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { imageOverlay } from "leaflet";

const swiperModule = [Pagination, Navigation];
const router = useRouter();
const token = ref(null);
const user = ref(null);
const isDropdownCategory = ref(false);
const isDropdownProfile = ref(false);
const isFilterCategory = ref(false);
const isFilterPrice = ref(false);
const caterings = ref(null);
const outletId = ref(null);
const priceFrom = ref(null);
const priceTo = ref(null);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  outletId.value = router.currentRoute.value.params.id;
  getDetails();
});

async function getDetails() {
  isFilterCategory.value = false;
  isFilterPrice.value = false;
  let _url = "";

  const selectedCategory = [];
  if (caterings.value) {
    caterings.value.categories.forEach((category) => {
      if (category.isSelected == true) {
        selectedCategory.push(category.id);
      }
    });
  }

  _url =
    "?category_id=" +
    selectedCategory.join(",") +
    "&min_price=" +
    (priceFrom.value ?? "") +
    "&max_price=" +
    (priceTo.value ?? "");

  await axios
    .get(`${PROD_URL}/api/caterings/${outletId.value}` + _url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      caterings.value = res.data.data.catering;

      caterings.value.categories.forEach((category) => {
        if (selectedCategory.includes(category.id)) {
          category.isSelected = true;
        } else {
          category.isSelected = false;
        }
      });
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function doSignOut() {
  axios
    .post(PROD_URL + "/api/auth/logout", "", {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.clear();

      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function chatOwner() {
  const query = await getDocs(collection(db, "users"));
  let isExistChat = false;
  query.forEach((doc) => {
    if (doc.id == outletId.value + "," + user.value.user.id) {
      isExistChat = true;
    }
  });
  if (!isExistChat) {
    const parentDocRef = doc(
      db,
      "users",
      outletId.value + "," + user.value.user.id
    );
    await setDoc(parentDocRef, { initialized: true }, { merge: true });
    const subcollectionRef = collection(parentDocRef, "chats");
    await addDoc(subcollectionRef, {
      from: "owner",
      text: "Hallo, Apa yang bisa kami bantu?",
      createdAt: new Date(),
    });
  }
  router.push(
    "/chat/customer?chat_id=" + outletId.value + "," + user.value.user.id
  );
}

function checkExpired(v) {
  const now = new Date();
  const targetDate = new Date(v);
  return now > targetDate;
}

function selectCategory(id) {
  caterings.value.categories.forEach((category) => {
    if (category.id == id) {
      category.isSelected = !category.isSelected;
    }
  });
}

function resetCategory() {
  caterings.value.categories.forEach((category) => {
    category.isSelected = false;
  });
  getDetails();
}

function resetPrice() {
  priceFrom.value = null;
  priceTo.value = null;
  getDetails();
}

function handleFilterCategory() {
  isFilterCategory.value = !isFilterCategory.value;
  isFilterPrice.value = false;
}

function handleFilterPrice() {
  isFilterPrice.value = !isFilterPrice.value;
  isFilterCategory.value = false;
}

function openDropdown() {
  isDropdownCategory.value = !isDropdownCategory.value;
}

function profileDropdown() {
  isDropdownProfile.value = !isDropdownProfile.value;
}

function gotoPremiumPage(subdomain){
  const _url = "https://" + subdomain + ".caricatering.id/"
  window.open(_url, "_blank");
}

function gotoPackageDetail(id) {
  router.push("/package-detail/" + id);
}

function gotoAuth() {
  router.push("/auth");
}

function gotoHome() {
  router.push("/");
}

function gotoOwner() {
  router.push("/owner-home");
}

async function gotoChat() {
  router.push("/chat/customer");
}
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <nav
      class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-white tw-shadow"
    >
      <button
        @click="gotoHome"
        class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4"
      >
        <img
          src="@/assets/caricatering.png"
          alt="Logo"
          class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12"
        />
        <a
          href="#"
          class="tw-text-catering tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg"
          >CariCatering.id</a
        >
      </button>
      <div class="tw-flex tw-items-center tw-space-x-4">
        <div class="tw-relative tw-hidden sm:tw-hidden md:tw-block lg:tw-block">
          <!-- <button
            @click="openDropdown"
            class="tw-flex tw-items-center tw-text-gray-700"
          >
            Cari Apa?
            <span
              class="tw-transition"
              :class="isDropdownCategory ? 'tw-rotate-180' : ''"
            >
              <svg
                class="tw-ml-1 tw-w-4 tw-h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button> -->
          <div
            class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
            :class="isDropdownCategory ? '' : 'tw-hidden'"
            style="z-index: 1"
          >
            <a
              href="#"
              class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
              >Option 1</a
            >
            <a
              href="#"
              class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
              >Option 2</a
            >
            <a
              href="#"
              class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
              >Option 3</a
            >
          </div>
        </div>
        <!-- <a
          href="#"
          class="tw-text-black tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >Pusat Bantuan</a
        >
        <a
          href="#"
          class="tw-text-black tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >Syarat dan Ketentuan</a
        > -->
        <div v-if="token" class="tw-relative">
          <button
            @click="profileDropdown"
            class="tw-flex tw-items-center tw-text-black"
          >
            Hallo, {{ user?.user?.name }}
            <span
              class="tw-transition"
              :class="isDropdownProfile ? 'tw-rotate-180' : ''"
            >
              <svg
                class="tw-ml-1 tw-w-4 tw-h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button>
          <div
            class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
            :class="isDropdownProfile ? '' : 'tw-hidden'"
            style="z-index: 1"
          >
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-2"
                :icon="['fas', 'comments']"
              />
              <a @click="gotoChat">Chat</a>
            </div>
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-3"
                :icon="['fas', 'user']"
              />
              <a @click="gotoOwner">Owner</a>
            </div>
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-3"
                :icon="['fas', 'sign-out']"
              />
              <a @click="doSignOut">Log Out</a>
            </div>
          </div>
        </div>
        <button
          v-if="!token"
          @click="gotoAuth"
          class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded"
        >
          Masuk
        </button>
      </div>
    </nav>

    <div
      class="tw-w-full tw-py-8 tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-space-y-8"
    >
      <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6 tw-flex-col">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-w-full">
          <img
            :src="caterings?.image.url_path"
            alt="Logo"
            class="tw-w-32 tw-h-32 tw-rounded-lg tw-object-cover"
          />
          <div class="tw-flex-1 tw-hidden sm:tw-block md:tw-block lg:tw-block">
            <h2 class="tw-text-xl tw-font-semibold">
              {{ caterings?.name }}
            </h2>
            <p class="tw-text-sm tw-text-gray-600">
              {{ caterings?.address }}
            </p>
            <a
              @click="gotoPremiumPage(caterings?.profile_page)"
              v-if="
                caterings?.profile_page != null &&
                !checkExpired(caterings?.profile_expired_at)
              "
              class="tw-text-sm tw-text-catering"
            >
              {{ caterings?.profile_page }} .caricatering.id
            </a>
          </div>
          <button
            @click="chatOwner"
            class="tw-flex tw-hidden sm:tw-flex md:tw-flex lg:tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-catering hover:tw-text-white focus:tw-outline-none focus:tw-ring focus:tw-ring-indigo-300 focus:tw-ring-opacity-80"
          >
            <font-awesome-icon class="tw-mr-2" :icon="['fas', 'paper-plane']" />
            Chat Catering
          </button>
        </div>
        <div
          class="tw-flex sm:tw-hidden md:tw-hidden lg:tw-hidden tw-space-x-6 tw-w-full"
        >
          <div class="tw-flex-1 tw-mt-4">
            <h2 class="tw-text-xl tw-font-semibold">
              {{ caterings?.name }}
            </h2>
            <p class="tw-text-sm tw-text-gray-600">
              {{ caterings?.address }}
            </p>
            <a
              @click="gotoPremiumPage(caterings?.profile_page)"
              v-if="
                caterings?.profile_page != null &&
                !checkExpired(caterings?.profile_expired_at)
              "
              class="tw-text-sm tw-text-catering"
            >
              {{ caterings?.profile_page }} .caricatering.id
            </a>
          </div>
        </div>
        <button
          @click="chatOwner"
          class="tw-flex sm:tw-hidden md:tw-hidden lg:tw-hidden tw-mt-4 tw-px-4 tw-py-2 tw-font-medium tw-tracking-wide tw-text-white tw-capitalize tw-transition-colors tw-duration-300 tw-transform tw-bg-catering tw-rounded-lg hover:tw-bg-catering focus:tw-outline-none focus:tw-ring focus:tw-ring-indigo-300 focus:tw-ring-opacity-80"
        >
          <font-awesome-icon class="tw-mr-2" :icon="['fas', 'paper-plane']" />
          Chat Catering
      </button>
      </div>

      <div
        class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4"
      >
        <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
          <h3 class="tw-font-semibold tw-mb-2">Tentang Kami</h3>
          <p class="tw-text-sm tw-text-gray-600">
            {{ caterings?.description }}
          </p>
        </div>
        <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
          <h3 class="tw-font-semibold tw-mb-2">Kategori</h3>
          <ul class="tw-text-sm tw-text-gray-600 tw-space-y-1">
            <li
              class="tw-m-0"
              v-for="(category, categoryIndex) in caterings?.categories"
              :key="categoryIndex"
            >
              <div>{{ category.id_name }}</div>
            </li>
          </ul>
        </div>
        <div class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
          <h3 class="tw-font-semibold tw-mb-2">Informasi Kontak</h3>
          <ul class="tw-text-sm tw-text-gray-600 tw-space-y-1">
            <li>
              <div class="tw-flex">
                <font-awesome-icon
                  class="tw-pt-2 tw-mr-2"
                  size="sm"
                  :icon="['fas', 'location-dot']"
                />
                <div class="tw-lowercase">
                  {{ caterings?.district.name }}, {{ caterings?.city.name }}
                </div>
              </div>
            </li>
            <li>
              <div class="tw-flex">
                <font-awesome-icon
                  class="tw-pt-2 tw-mr-2"
                  size="sm"
                  :icon="['fas', 'inbox']"
                />
                <div class="tw-lowercase tw-break-all">
                  {{ caterings?.email }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <h3 class="tw-font-bold tw-text-black tw-text-xl tw-mb-3">
          Paket Katering
        </h3>
        <div
          class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-4 tw-py-4 tw-py-4"
        >
          <div
            class="tw-relative tw-h-fit tw-w-full tw-mr-2 tw-rounded tw-border tw-border-gray-300"
          >
            <div
              @click="handleFilterCategory"
              class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition"
            >
              <span class="tw-text-sm tw-font-medium">Kategori</span>
              <span
                class="tw-transition"
                :class="isFilterCategory ? 'tw-rotate-180' : ''"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="tw-h-4 tw-w-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </div>
            <div
              class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full"
              style="z-index: 2"
              v-show="isFilterCategory"
            >
              <header
                class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3"
              >
                <span @click="resetCategory" class="tw-text-sm tw-text-gray-700"
                  >Reset</span
                >
                <button
                  @click="getDetails"
                  class="tw-bg-catering tw-text-white tw-px-5 tw-py-2 tw-rounded-full"
                >
                  Pilih
                </button>
              </header>
              <ul class="tw-space-y-1 tw-border-t tw-border-gray-200 tw-p-4">
                <li
                  v-for="(category, categoryIndex) in caterings?.categories"
                  :key="categoryIndex"
                >
                  <label
                    for="FilterInStock"
                    class="tw-inline-flex tw-items-center tw-gap-4"
                  >
                    <input
                      @click="selectCategory(category.id)"
                      type="checkbox"
                      :id="category.id"
                      class="tw-size-5 tw-rounded tw-border-gray-300"
                      v-model="category.isSelected"
                    />
                    <span class="tw-text-sm tw-font-medium tw-text-gray-700">
                      {{ category.id_name }}
                    </span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="tw-relative tw-h-fit tw-w-full tw-rounded tw-border tw-border-gray-300"
          >
            <div
              @click="handleFilterPrice"
              class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition"
            >
              <span class="tw-text-sm tw-font-medium">Harga</span>
              <span
                class="tw-transition"
                :class="isFilterPrice ? 'tw-rotate-180' : ''"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="tw-h-4 tw-w-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </div>
            <div
              class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full"
              style="z-index: 2"
              v-show="isFilterPrice"
            >
              <header
                class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3"
              >
                <span @click="resetPrice" class="tw-text-sm tw-text-gray-700"
                  >Reset</span
                >
                <button
                  @click="getDetails"
                  class="tw-bg-catering tw-text-white tw-px-5 tw-py-2 tw-rounded-full"
                >
                  Pilih
                </button>
              </header>
              <div class="tw-border-t tw-border-gray-200 tw-p-4">
                <div class="tw-flex tw-justify-between tw-gap-4">
                  <label
                    for="FilterPriceFrom"
                    class="tw-flex tw-items-center tw-gap-2"
                  >
                    <span class="tw-text-sm tw-text-gray-600">Rp</span>
                    <input
                      type="number"
                      id="FilterPriceFrom"
                      placeholder="Dari"
                      class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm"
                      v-model="priceFrom"
                    />
                  </label>
                  <label
                    for="FilterPriceTo"
                    class="tw-flex tw-items-center tw-gap-2"
                  >
                    <span class="tw-text-sm tw-text-gray-600">Rp</span>
                    <input
                      type="number"
                      id="FilterPriceTo"
                      placeholder="Sampai"
                      class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm"
                      v-model="priceTo"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4"
        >
          <div
            @click="gotoPackageDetail(pack.id)"
            class="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6"
            v-for="(pack, packageIndex) in caterings?.packages"
            :key="packageIndex"
          >
            <div class="tw-h-48 tw-w-full">
              <swiper
                class="swiper-backface-hidden"
                id="product_photos_swiper"
                :pagination="{
                  dynamicBullets: true,
                }"
                :modules="swiperModule"
              >
                <swiper-slide
                  v-for="(image, imageIndex) in pack.images"
                  :key="imageIndex"
                >
                  <img
                    :src="image.url_path"
                    alt=""
                    class="tw-w-full tw-h-48 tw-object-cover tw-rounded-lg"
                  />
                </swiper-slide>
              </swiper>
            </div>
            <div class="tw-text-sm tw-mt-4">{{ pack.category.id_name }}</div>
            <div class="tw-text-lg tw-text-black">{{ pack.name }}</div>
            <div class="tw-flex tw-flex-wrap tw-mt-1">
              <div
                v-for="(menu, menuIndex) in pack.menus"
                :key="menuIndex"
                class="tw-rounded-lg tw-w-fit tw-bg-sky-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-sky-600"
              >
                {{ menu.name }}
              </div>
            </div>
            <p class="tw-text-gray-400 tw-mt-1">
              *pilihan {{ pack.min_menu_selection }} -
              {{ pack.max_menu_selection }} menu / paket
            </p>
            <p class="tw-text-catering tw-font-semibold tw-mt-1">
              Rp{{ $numFormat(pack.price) }} / paket
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
