<script setup>
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue"
import { ref, onMounted, defineProps, defineEmits, toRef, toRaw } from "vue"
// import { PROD_URL } from "@/utils/url"
import axios from "axios"
import { useRouter } from "vue-router"

const router = useRouter()
const token = ref(null)
const selectedDistrict = ref([])
const emits = defineEmits(["getCaterings", "closeDialog"])
const props = defineProps({
  item: {
    type: Array,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
})

const reactiveItem = toRef(() => toRaw(props)["item"])

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null
  selectedDistrict.value =
    JSON.parse(localStorage.getItem("selectedDistrict")) ?? []
})

function selectDistrict(id) {
  const tempSelectedDistrict = selectedDistrict.value
  if (tempSelectedDistrict.includes(id)) {
    let index = toRaw(tempSelectedDistrict).indexOf(id)
    if (index !== -1) {
      tempSelectedDistrict.splice(index, 1)
    }
  } else {
    tempSelectedDistrict.push(id)
  }
  selectedDistrict.value = tempSelectedDistrict
}

function isDistrictSelected(id) {
  return selectedDistrict.value.some((v) => v == id)
}

const getCaterings = (v) => {
  if (v == "add") {
    emits("getCaterings", {
      selectedDistrict: selectedDistrict.value,
    })
  } else {
    localStorage.setItem("selectedDistrict", JSON.stringify([]))
    emits("getCaterings", {
      selectedDistrict: [],
    })
  }
}

const openDialog = () => {
  emits("closeDialog")
}
</script>

<template>
  <Dialog
    as="div"
    class="tw-fixed tw-inset-0 tw-z-[99] tw-overflow-y-auto"
    :open="isOpen"
    @close="openDialog"
  >
    <div class="tw-flex tw-items-center tw-justify-center tw-min-h-screen">
      <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-black tw-opacity-30" />
      <div
        class="tw-m-3 tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all tw-max-w-lg tw-w-full"
      >
        <DialogTitle
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900 tw-p-1 tw-border-b"
        >
          <header
            class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3"
          >
            <span class="tw-text-xl tw-text-gray-700">Filter Lokasi</span>
            <div class="tw-flex">
              <button v-if="selectedDistrict.length > 0"
                @click="getCaterings('reset')"
                class="tw-bg-white tw-text-black tw-text-base tw-px-5 tw-py-2 tw-rounded-full"
              >
                Reset
              </button>
              <button
                @click="getCaterings('add')"
                class="tw-bg-catering tw-text-white tw-px-5 tw-py-2 tw-ml-2 tw-rounded-full"
              >
                Pilih
              </button>
            </div>
          </header>
        </DialogTitle>
        <div class="tw-p-4 tw-flex tw-gap-4 tw-items-center">
          <div class="tw-grid tw-divide-y tw-divide-neutral-200 tw-w-full">
            <div
              class="tw-py-5"
              v-for="(city, cityIndex) in reactiveItem"
              :key="cityIndex"
            >
              <details class="tw-group">
                <summary
                  class="tw-flex tw-justify-between tw-items-center tw-font-medium tw-cursor-pointer tw-list-none"
                >
                  <span class="tw-text-black tw-text-lg">{{ city.name }}</span>
                  <font-awesome-icon
                    icon="fa-solid fa-angle-down"
                    class="tw-text-black icon-lg"
                  />
                </summary>
                <fieldset class="tw-mt-4">
                  <legend class="tw-sr-only">Checkboxes</legend>
                  <div class="tw-space-y-2">
                    <label
                      v-for="(district, districtIndex) in city.districts"
                      :key="districtIndex"
                      :for="district.id"
                      class="tw-flex tw-cursor-pointer tw-items-start tw-gap-4 tw-rounded-lg tw-border tw-border-gray-200 tw-p-4 tw-transition hover:tw-bg-gray-50 has-[:checked]:tw-bg-blue-50"
                    >
                      <div class="tw-flex tw-items-center">
                        &#8203;
                        <input
                          :value="
                            selectedDistrict.some((v) => v == district.id)
                          "
                          :checked="isDistrictSelected(district.id)"
                          @click="selectDistrict(district.id)"
                          type="checkbox"
                          class="tw-size-4 tw-rounded tw-border-gray-300"
                          :id="district.id"
                        />
                      </div>
                      <div>
                        <strong class="tw-font-medium tw-text-gray-900">{{
                          district.name
                        }}</strong>
                      </div>
                    </label>
                  </div>
                </fieldset>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
