import { createWebHistory, createRouter } from "vue-router";

// views without layouts
import Admin from "@/views/Admin.vue";
import Index from "@/views/Index.vue";
import Auth from "@/views/Auth.vue";
import Search from "@/views/Search.vue";
import Home from "@/views/Home.vue";
import Chat from "@/views/Chat.vue";
import OwnerHome from "@/views/OwnerHome.vue";
import OwnerCatering from "@/views/OwnerCatering.vue";
import OutletDetail from "@/views/OutletDetail.vue";
import PackageDetail from "@/views/PackageDetail.vue";
import CateringForm from "@/views/CateringForm.vue";
import PackageForm from "@/views/PackageForm.vue";
import PromotionCatering from "@/views/PromotionCatering.vue";
import PromotionPayment from "@/views/PromotionPayment.vue";
import PremiumPage from "@/views/PremiumPage.vue";
import PremiumPagePayment from "@/views/PremiumPagePayment.vue";

// lazy-loaded
let routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/admin",
    component: Admin,
  },
  {
    path: "/chat/:page?",
    component: Chat,
  },
  {
    path: "/auth",
    component: Auth,
  },
  {
    path: "/search",
    component: Search,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/outlet-detail/:id?",
    component: OutletDetail,
  },
  {
    path: "/package-detail/:id?",
    component: PackageDetail,
  },
  {
    path: "/owner-home",
    component: OwnerHome,
  },
  {
    path: "/owner-catering",
    component: OwnerCatering,
  },
  {
    path: "/catering-form",
    component: CateringForm,
  },
  {
    path: "/package-form",
    component: PackageForm,
  },
  {
    path: "/promotion-catering",
    component: PromotionCatering,
  },
  {
    path: "/promotion-payment/:id?",
    component: PromotionPayment,
  },
  {
    path: "/premium-page",
    component: PremiumPage,
  },
  {
    path: "/premium-page-payment/:id?",
    component: PremiumPagePayment,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
