<script setup>
import { ref, onMounted, watch } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const token = ref(null);
const selectedImage = ref(null);
const imageUrl = ref(null);
const isLoading = ref(false);
const user = ref(null);
const formData = ref({
  subDomain: "",
  note: "",
});
const premiumPageData = ref([
  {
    id: 1,
    url: "https://profile1.caricatering.id/",
    isSelected: false,
  },
  {
    id: 2,
    url: "https://profile2.caricatering.id/",
    isSelected: false,
  },
  {
    id: 3,
    url: "https://profile3.caricatering.id/",
    isSelected: false,
  },
]);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
});

async function handleSubmit() {
  if (!isLoading.value) {
    isLoading.value = true;
    const formDataSubmit = {
      catering_id: router.currentRoute.value.params.id,
      template_number: premiumPageData.value.find(
        (item) => item.isSelected === true
      ).id,
      subdomain: formData.value.subDomain,
      note: formData.value.note,
    };
    if (selectedImage.value) {
      formDataSubmit.payment = selectedImage.value;
    }
    console.log(formDataSubmit);

    await axios
      .post(`${PROD_URL}/api/owner/profile-requests`, formDataSubmit, {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        isLoading.value = false;
        gotoPremium();
      })
      .catch((err) => {
        isLoading.value = false;
        if (err.response.status == 401) {
          router.push("/auth");
        }
      });
  }
}

function onImageChange(event) {
  selectedImage.value = event.target.files[0];
  if (selectedImage.value) {
    imageUrl.value = URL.createObjectURL(selectedImage.value);
  }
}

function selectPremiumPage(id) {
  premiumPageData.value.forEach((v) => {
    if (v.id == id) {
      v.isSelected = true;
    } else {
      v.isSelected = false;
    }
  });
}

function openNewTab(v) {
  window.open(v, "_blank");
}

function gotoCateringHome() {
  router.push("/owner-catering");
}

function gotoOwnerHome() {
  router.push("/owner-home");
}

function gotoHome() {
  router.push("/home");
}

function gotoPromotionCatering() {
  router.push("/promotion-catering");
}

function gotoPremium() {
  router.push("/premium-page");
}

function gotoPremiumPage() {
  router.push("/premium-page");
}
</script>

<template>
  <div class="tw-flex tw-min-h-screen tw-bg-gray-50">
    <aside class="tw-w-64 tw-bg-white tw-text-black">
      <nav>
        <div
          class="tw-flex tw-h-screen tw-flex-col tw-justify-between tw-border-e tw-bg-white"
        >
          <div class="tw-px-4 tw-py-6">
            <div
              class="tw-sticky tw-inset-x-0 tw-bottom-0 tw-border-t tw-border-gray-100"
            >
              <a
                href="#"
                class="tw-flex tw-items-center tw-gap-2 tw-bg-white hover:tw-bg-gray-50"
              >
                <img
                  alt=""
                  src="https://images.unsplash.com/photo-1600486913747-55e5470d6f40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  class="tw-size-10 tw-rounded-full tw-object-cover"
                />

                <div>
                  <p class="tw-text-xs">
                    <strong class="tw-block tw-text-black tw-font-medium"
                      >Hallo,</strong
                    >

                    <span class="tw-text-black">{{ user?.user?.name }}</span>
                  </p>
                </div>
              </a>
            </div>
            <ul class="tw-mt-6 tw-space-y-1">
              <li>
                <button
                  @click="gotoOwnerHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  @click="gotoCateringHome()"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                >
                  Catering Saya
                </button>
              </li>
              <li>
                <details
                  class="tw-group [&_summary::-webkit-details-marker]:tw-hidden"
                >
                  <summary
                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                  >
                    <span class="tw-text-sm tw-font-medium">Fitur Promosi</span>
                    <span
                      class="tw-shrink-0 tw-transition tw-duration-300 tw-group-open:-tw-rotate-180"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-size-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>
                  <ul class="tw-mt-2 tw-space-y-1 tw-px-4">
                    <li>
                      <button
                        @click="gotoPromotionCatering()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Highlight Catering
                      </button>
                    </li>
                    <li>
                      <button
                        @click="gotoPremiumPage()"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Halaman Premium Catering
                      </button>
                    </li>
                    <li>
                      <button
                        href="#"
                        class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-catering"
                      >
                        Foto dan Desain Catering
                      </button>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <button
                  @click="gotoHome"
                  class="tw-block tw-w-full tw-text-start tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-red-100 hover:tw-text-red-600"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>

    <div
      class="tw-align-bottom tw-text-left tw-overflow-hidden sm:tw-align-middle sm:tw-max-w-4xl sm:tw-w-full tw-mx-auto tw-relative tw-px-6 tw-py-12"
    >
      <div
        class="sm:tw-p-12 tw-p-6 tw-pt-14 tw-bg-white tw-rounded-lg tw-shadow-md"
      >
        <h2
          class="tw-sm:text-3xl tw-text-2xl tw-font-bold tw-text-center tw-text-black tw-mb-4"
        >
          Pembayaran Premium Page
        </h2>
        <div class="tw-block tw-mt-8 tw-text-sm tw-font-medium tw-leading-6">
          Nama Catering
        </div>
        <div
          class="tw-sm:text-lg tw-text-base tw-text-left tw-text-black tw-mb-2"
        >
          Catering
        </div>
        <div class="tw-block tw-mt-2 tw-text-sm tw-font-medium tw-leading-6">
          Premium Page
        </div>
        <div
          v-for="(pre, preIndex) in premiumPageData"
          :key="preIndex"
          class="tw-p-2 tw-mt-2 tw-space-y-3 tw-border-2 tw-border-black tw-rounded-xl"
        >
          <div class="tw-flex tw-flex-col">
            <h1
              class="tw-text-base tw-pt-1.5 tw-font-semibold tw-capitalize tw-text-black"
            >
              Premium Profile Page {{ pre.id }}
            </h1>
            <div class="tw-flex tw-mt-2">
              <button
                @click="openNewTab(pre.url)"
                class="tw-h-fit tw-inline-flex tw-py-2 tw-px-3 tw-capitalize tw-bg-black tw-rounded-full tw-text-white hover:tw-text-white"
              >
                Lihat
              </button>
              <button
                @click="selectPremiumPage(pre.id)"
                :class="pre.isSelected ? 'tw-bg-catering' : 'tw-bg-black'"
                class="tw-h-fit tw-inline-flex tw-py-2 tw-px-3 tw-ml-2 tw-capitalize tw-rounded-full tw-text-white hover:tw-text-white"
              >
                {{ pre.isSelected ? "Terpilih" : "Pilih" }}
              </button>
            </div>
          </div>
        </div>
        <label
          for="subDomain"
          class="tw-block tw-mt-2 tw-text-sm tw-font-medium tw-leading-6"
        >
          Sub Domain
        </label>
        <div class="tw-mt-2">
          <input
            v-model="formData.subDomain"
            id="subDomain"
            type="text"
            class="tw-block tw-w-full tw-mb-2 tw-rounded-md tw-border-0 tw-py-2.5 tw-px-3 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 tw-focus:tw-ring-2 tw-focus:tw-ring-inset tw-focus:tw-ring-primary tw-sm:text-sm tw-sm:leading-6 tw-transition-all tw-duration-500"
            required
          />
        </div>
        <label
          for="note"
          class="tw-block tw-text-sm tw-font-medium tw-leading-6"
          >Catatan</label
        >
        <textarea
          v-model="formData.note"
          id="note"
          class="tw-block tw-p-2.5 tw-max-h-20 tw-min-h-[80px] tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-bottom-0 tw-rounded-lg tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-primary tw-focus:ring-2 tw-focus:ring-inset tw-transition-all tw-duration-500"
        ></textarea>
        <label class="tw-block tw-mt-4 tw-text-sm tw-font-medium tw-leading-6">
          Bukti Pembayaran Premium Page
        </label>
        <div class="tw-flex tw-flex-col tw-mb-2">
          <div class="w-text-base tw-text-left tw-text-black">
            Kirim bukti pembayaran di:
          </div>
          <div class="w-text-base tw-text-left tw-text-black">
            BCA 1300337381 Deni Dwi Candra
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
          <label
            for="dropzone-file"
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-40 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 tw-hover:bg-gray-100"
          >
            <img
              class="tw-w-36 tw-h-36"
              :src="imageUrl"
              v-if="imageUrl"
              alt="Selected image"
            />
            <div
              v-else
              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6"
            >
              <svg
                class="tw-w-8 tw-h-8 tw-mb-4 tw-text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h3"
                />
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m9 11 2 2 4-4M12 15v-7"
                />
              </svg>
              <p class="tw-mb-2 tw-text-sm tw-text-gray-500">
                <span class="tw-font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p class="tw-text-xs tw-text-gray-500">PNG dan JPG(MAX. 2mb)</p>
            </div>
            <input
              @change="onImageChange"
              id="dropzone-file"
              type="file"
              class="tw-hidden"
              accept="image/*"
            />
          </label>
        </div>
        <div
          class="tw-flex tw-justify-between tw-items-center tw-gap-8 tw-mt-8"
        >
          <button
            @click="handleSubmit()"
            type="submit"
            class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-8 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold tw-focus-visible:tw-outline tw-focus-visible:tw-outline-2 tw-focus-visible:tw-outline-offset-2 tw-bg-green-400 hover:tw-bg-green-300 focus-visible:tw-outline-green-200 active:tw-scale-95 tw-transition-all tw-duration-500"
          >
            Proses
          </button>
          <button
            @click="gotoPremium()"
            type="button"
            class="tw-flex tw-justify-center tw-rounded-md tw-shadow-md tw-px-5 tw-py-2 tw-w-fit tw-text-lg tw-text-slate-50 tw-font-semibold focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-bg-red-700 hover:tw-bg-red-700 focus-visible:tw-outline-red active:tw-scale-95 tw-transition-all tw-duration-500 tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >
            Kembali
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
