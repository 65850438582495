<script setup>
import { ref, onMounted, toRaw } from "vue";
import { PROD_URL } from "@/utils/url";
import axios from "axios";
import { useRouter } from "vue-router";
import LocationModal from "@/components/LocationModal.vue";

const router = useRouter();
const token = ref(null);
const user = ref(null);
const isOpen = ref(null);
const isDropdownCategory = ref(false);
const isDropdownProfile = ref(false);
const isFilterCategory = ref(false);
const isFilterPrice = ref(false);
const caterings = ref([]);
const categories = ref([]);
const cities = ref(null);
const selectedDistrict = ref([]);
const priceFrom = ref(null);
const priceTo = ref(null);

onMounted(async () => {
  token.value = localStorage.getItem("token") ?? null;
  selectedDistrict.value =
    JSON.parse(localStorage.getItem("selectedDistrict")) ?? [];
  user.value = JSON.parse(localStorage.getItem("user")) ?? null;
  getCaterings();
});

async function getCaterings(data = null) {
  isFilterCategory.value = false;
  isFilterPrice.value = false;
  let _url = "";

  const selectedCategory = [];
  categories.value.forEach((category) => {
    if (category.isSelected == true) {
      selectedCategory.push(category.id);
    }
  });

  if (data) {
    selectedDistrict.value = toRaw(data.selectedDistrict);
    closeAddOnModal();
  }
  selectedDistrict.value =
    selectedDistrict.value == undefined ? [] : selectedDistrict.value;
  _url =
    "?districts=" +
    selectedDistrict.value.join(",") +
    "&categories=" +
    selectedCategory.join(",") +
    "&min_price=" +
    (priceFrom.value ?? "") +
    "&max_price=" +
    (priceTo.value ?? "");

  await axios
    .get(`${PROD_URL}/api/caterings` + _url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      caterings.value = res.data.data.caterings;
      getcategories();
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getcategories() {
  await axios
    .get(`${PROD_URL}/api/categories`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      if (!categories.value.some((item) => item.isSelected)) {
        categories.value = res.data.data.categories;
      }

      getCities();
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

async function getCities() {
  await axios
    .get(`${PROD_URL}/api/regions/cities?province_code=35&districts=1`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      cities.value = res.data.data.cities;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function doSignOut() {
  axios
    .post(PROD_URL + "/api/auth/logout", "", {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.clear();

      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status == 401) {
        router.push("/auth");
      }
    });
}

function checkExpired(v) {
  const now = new Date();
  const targetDate = new Date(v);
  return now > targetDate;
}

function selectCategory(id) {
  categories.value.forEach((category) => {
    if (category.id == id) {
      category.isSelected = !category.isSelected;
    }
  });
}

function resetCategory() {
  categories.value.forEach((category) => {
    category.isSelected = false;
  });
  getCaterings();
}

function resetPrice() {
  priceFrom.value = null;
  priceTo.value = null;
  getCaterings();
}

function openLocationModal() {
  isOpen.value = "location";
}

const closeAddOnModal = () => {
  isOpen.value = "main";
};

function handleFilterCategory() {
  isFilterCategory.value = !isFilterCategory.value;
  isFilterPrice.value = false;
}

function handleFilterPrice() {
  isFilterPrice.value = !isFilterPrice.value;
  isFilterCategory.value = false;
}

function openDropdown() {
  isDropdownCategory.value = !isDropdownCategory.value;
}

function profileDropdown() {
  isDropdownProfile.value = !isDropdownProfile.value;
}

function gotoDetail(id) {
  router.push("/outlet-detail/" + id);
}

function gotoAuth() {
  router.push("/auth");
}

function gotoHome() {
  router.push("/");
}

function gotoOwner() {
  router.push("/owner-home");
}

function gotoChat() {
  router.push("/chat/customer");
}
</script>

<template>
  <LocationModal
    :item="JSON.parse(JSON.stringify(cities))"
    @close-dialog="closeAddOnModal"
    :isOpen="isOpen == 'location'"
    @get-caterings="getCaterings"
  ></LocationModal>
  <div class="tw-flex tw-flex-col">
    <nav
      class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-bg-white tw-shadow"
    >
      <button
        @click="gotoHome"
        class="tw-flex tw-items-center tw-space-x-2 sm:tw-space-x-2 md:tw-space-x-4 lg:tw-space-x-4"
      >
        <img
          src="@/assets/caricatering.png"
          alt="Logo"
          class="tw-h-10 sm:tw-h-10 md:tw-h-12 lg:tw-h-12"
        />
        <a
          href="#"
          class="tw-text-catering tw-font-bold tw-text-md sm:tw-text-md md:tw-text-lg lg:tw-text-lg"
          >CariCatering.id</a
        >
      </button>
      <div class="tw-flex tw-items-center tw-space-x-4">
        <div class="tw-relative tw-hidden sm:tw-hidden md:tw-block lg:tw-block">
          <!-- <button
            @click="openDropdown"
            class="tw-flex tw-items-center tw-text-gray-700"
          >
            Cari Apa?
            <span
              class="tw-transition"
              :class="isDropdownCategory ? 'tw-rotate-180' : ''"
            >
              <svg
                class="tw-ml-1 tw-w-4 tw-h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button> -->
          <div
            class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
            :class="isDropdownCategory ? '' : 'tw-hidden'"
            style="z-index: 1"
          >
            <a
              href="#"
              class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
              >Option 1</a
            >
            <a
              href="#"
              class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
              >Option 2</a
            >
            <a
              href="#"
              class="tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100"
              >Option 3</a
            >
          </div>
        </div>
        <!-- <a
          href="#"
          class="tw-text-black tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >Pusat Bantuan</a
        >
        <a
          href="#"
          class="tw-text-black tw-hidden sm:tw-hidden md:tw-block lg:tw-block"
          >Syarat dan Ketentuan</a
        > -->
        <div v-if="token" class="tw-relative">
          <button
            @click="profileDropdown"
            class="tw-flex tw-items-center tw-text-black"
          >
            Hallo, {{ user?.user?.name }}
            <span
              class="tw-transition"
              :class="isDropdownProfile ? 'tw-rotate-180' : ''"
            >
              <svg
                class="tw-ml-1 tw-w-4 tw-h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.707a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414L10 12.414l-4.707-4.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button>
          <div
            class="tw-absolute tw-mt-2 tw-w-48 tw-bg-white tw-border tw-border-gray-200 tw-rounded tw-shadow-lg"
            :class="isDropdownProfile ? '' : 'tw-hidden'"
            style="z-index: 1"
          >
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-2"
                :icon="['fas', 'comments']"
              />
              <a @click="gotoChat">Chat</a>
            </div>
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-3"
                :icon="['fas', 'user']"
              />
              <a @click="gotoOwner">Owner</a>
            </div>
            <div
              class="tw-flex tw-block tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-200"
            >
              <font-awesome-icon
                class="tw-pt-1 tw-pr-3"
                :icon="['fas', 'sign-out']"
              />
              <a @click="doSignOut">Log Out</a>
            </div>
          </div>
        </div>
        <button
          v-if="!token"
          @click="gotoAuth"
          class="tw-bg-catering tw-text-white tw-text-xs sm:tw-text-xs md:tw-text-sm lg:tw-text-sm tw-py-2 sm:tw-py-2 md:tw-py-3 lg:tw-py-3 tw-px-4 sm:tw-px-4 md:tw-px-5 lg:tw-px-5 tw-rounded"
        >
          Masuk
        </button>
      </div>
    </nav>

    <div
      class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-4 tw-py-4 tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32"
    >
      <div
        class="tw-h-full tw-w-full tw-mr-2 tw-rounded tw-border tw-border-gray-300"
      >
        <button
          @click="openLocationModal"
          class="tw-flex tw-items-center tw-justify-between tw-h-full tw-w-full tw-gap-2 tw-bg-white tw-px-4 tw-py-2 tw-text-gray-900 tw-transition"
        >
          <span class="tw-text-sm tw-font-medium">Lokasi</span>
          <span class="tw-transition">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="tw-h-4 tw-w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        class="tw-relative tw-h-fit tw-w-full tw-mr-2 tw-rounded tw-border tw-border-gray-300"
      >
        <div
          @click="handleFilterCategory"
          class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition"
        >
          <span class="tw-text-sm tw-font-medium">Layanan</span>
          <span
            class="tw-transition"
            :class="isFilterCategory ? 'tw-rotate-180' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="tw-h-4 tw-w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </div>
        <div
          class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full"
          style="z-index: 1"
          v-show="isFilterCategory"
        >
          <header
            class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3"
          >
            <span @click="resetCategory" class="tw-text-sm tw-text-gray-700"
              >Reset</span
            >
            <button
              @click="getCaterings"
              class="tw-bg-catering tw-text-white tw-px-5 tw-py-2 tw-rounded-full"
            >
              Pilih
            </button>
          </header>
          <ul class="tw-space-y-1 tw-border-t tw-border-gray-200 tw-p-4">
            <li
              v-for="(category, categoryIndex) in categories"
              :key="categoryIndex"
            >
              <label
                for="FilterInStock"
                class="tw-inline-flex tw-items-center tw-gap-4"
              >
                <input
                  @click="selectCategory(category.id)"
                  type="checkbox"
                  :id="category.id"
                  class="tw-size-5 tw-rounded tw-border-gray-300"
                  v-model="category.isSelected"
                />
                <span class="tw-text-sm tw-font-medium tw-text-gray-700">
                  {{ category.id_name }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div
        class="tw-relative tw-h-fit tw-w-full tw-rounded tw-border tw-border-gray-300"
      >
        <div
          @click="handleFilterPrice"
          class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-gray-900 tw-transition"
        >
          <span class="tw-text-sm tw-font-medium">Harga</span>
          <span
            class="tw-transition"
            :class="isFilterPrice ? 'tw-rotate-180' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="tw-h-4 tw-w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </div>
        <div
          class="tw-border tw-border-gray-300 tw-bg-white tw-absolute tw-w-full"
          style="z-index: 1"
          v-show="isFilterPrice"
        >
          <header
            class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3"
          >
            <span @click="resetPrice" class="tw-text-sm tw-text-gray-700"
              >Reset</span
            >
            <button
              @click="getCaterings"
              class="tw-bg-catering tw-text-white tw-px-5 tw-py-2 tw-rounded-full"
            >
              Pilih
            </button>
          </header>
          <div class="tw-border-t tw-border-gray-200 tw-p-4">
            <div class="tw-flex tw-justify-between tw-gap-4">
              <label
                for="FilterPriceFrom"
                class="tw-flex tw-items-center tw-gap-2"
              >
                <span class="tw-text-sm tw-text-gray-600">Rp</span>
                <input
                  type="number"
                  id="FilterPriceFrom"
                  placeholder="Dari"
                  class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm"
                  v-model="priceFrom"
                />
              </label>
              <label
                for="FilterPriceTo"
                class="tw-flex tw-items-center tw-gap-2"
              >
                <span class="tw-text-sm tw-text-gray-600">Rp</span>
                <input
                  type="number"
                  id="FilterPriceTo"
                  placeholder="Sampai"
                  class="tw-w-full tw-rounded-md tw-border-gray-200 tw-shadow-sm sm:tw-text-sm"
                  v-model="priceTo"
                />
              </label>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <p
      class="tw-pt-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-text-black tw-text-xl"
    >
      Hasil Pencarian
    </p>
    <div
      class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-gap-4 tw-py-4 tw-px-4 sm:tw-px-4 md:tw-px-32 lg:tw-px-32 tw-pb-12"
    >
      <div
        @click="gotoDetail(catering.id)"
        v-for="(catering, cateringIndex) in caterings"
        :key="cateringIndex"
      >
        <div class="tw-w-full tw-h-1/2 tw-rounded-lg tw-shadow-md tw-relative">
          <div
            class="tw-w-full tw-overflow-hidden tw-bg-white tw-rounded-lg tw-shadow-lg"
          >
            <img
              class="tw-object-cover tw-object-center tw-w-full tw-h-40"
              :src="catering.image.url_path"
              alt="avatar"
            />
            <div class="tw-px-6 tw-py-4">
              <div 
              v-if="
                catering.is_highlighted != null &&
                !checkExpired(catering.highlight_expired_at)
              "
                class="tw-rounded-lg tw-w-fit tw-bg-yellow-100 tw-py-1.5 tw-px-2 tw-mr-1 tw-mt-1 tw-text-xs tw-font-semibold tw-text-yellow-600"
              >
                Highlight
              </div>
              <p class="tw-text-gray-400 text-ellipsis">
                {{ catering.categories.map((item) => item.id_name).join(", ") }}
              </p>
              <div
                class="tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-font-semibold tw-text-gray-700 text-ellipsis"
              >
                {{ catering.name }}
              </div>
              <div
                class="tw-flex tw-mt-1 tw-text-base sm:tw-text-base md:tw-text-lg lg:tw-text-lg tw-text-catering"
              >
                Rp. {{ $numFormat(catering.min_price) }} - Rp.
                {{ $numFormat(catering.max_price) }}
              </div>
              <div class="tw-flex tw-mt-2 tw-text-gray-400">
                <h1 class="tw-text-sm">
                  {{ catering.district.name }}, {{ catering.city.name }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
